import { RootState } from 'app/store';

export const selectOrdersData = () => (state: RootState) =>
  state.warehouseOrders.orders;
export const selectOrdersLimit = () => (state: RootState) =>
  state.warehouseOrders.limit;
export const selectOrdersPage = () => (state: RootState) =>
  state.warehouseOrders.page;
export const selectOrdersLoading = () => (state: RootState) =>
  state.warehouseOrders.loading;

export const selectOrderLoadingById = () => (state: RootState) =>
  state.warehouseOrders.loadingById;

export const selectOrderDataById = () => (state: RootState) =>
  state.warehouseOrders.orderById;
