import { RootState } from 'app/store';

export const selectAllProducts = () => (state: RootState) =>
  state.products.products;
export const selectAllProductsLoading = () => (state: RootState) =>
  state.products.loading;
export const selectProductsLimit = () => (state: RootState) =>
  state.products.limit;
export const selectProductById = () => (state: RootState) =>
  state.products.productById;
export const selectProductsByCode = () => (state: RootState) =>
  state.products.productsByCode;

export const selectLoadingAction = () => (state: RootState) =>
  state.products.loadingAction;
