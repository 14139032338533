import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { AuthButton } from '../styled';

const { Title } = Typography;

const MessageChangePassword: FC = () => {
  // translation
  const { t } = useTranslation();
  // router
  const navigate = useNavigate();

  return (
    <>
      <Title level={3} style={{ marginBottom: 24 }}>
        {t('auth.success_message_title')}
      </Title>
      <AuthButton
        type="primary"
        shape="round"
        size="large"
        onClick={() => navigate('/login')}
      >
        {t('auth.login')}
      </AuthButton>
    </>
  );
};

export default MessageChangePassword;
