import { Button, Flex, Input, Modal, Typography } from 'antd';
import { ILocations } from 'app/features/locations/types';
import { selectProductsByCode } from 'app/features/products/selectors';
import {
  getProductByCode,
  setProductByCode
} from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { setLocationModalType } from 'app/features/locationModal/slice';

import { _debounce } from 'utils/helpers';
import { StyledListItem } from './styled';

const { Paragraph } = Typography;

interface IProps {
  location?: ILocations;
}

const debounce = _debounce();

const highlightText = (text: string, searchTerm: string) => {
  if (!searchTerm) return text;
  const regex = new RegExp(`(${searchTerm})`, 'gi');

  return text?.split(regex).map((part, index) =>
    part.toLowerCase() === searchTerm.toLowerCase() ? (
      <span key={index} style={{ color: '#1677ff', fontWeight: 500 }}>
        {part}
      </span>
    ) : (
      part
    )
  );
};

const SearchProductModal: FC<IProps> = ({ location }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [search, setSearch] = useState('');
  const products = useAppSelector(selectProductsByCode());

  useEffect(() => {
    if (search) {
      debounce(() =>
        dispatch(getProductByCode({ name: search, id: location?.id }))
      );
    } else {
      dispatch(setProductByCode(null));
    }
  }, [dispatch, location?.id, search]);

  const onChange = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <Modal
        title={`${t('locations.location_name')}: ${location?.name}`}
        footer={null}
        onCancel={() => {
          setSearch('');
          dispatch(setProductByCode(null));
          dispatch(setLocationModalType(null));
        }}
        open
      >
        <Flex vertical>
          <Input.Search
            size="middle"
            placeholder={t('locations.search_by_code')}
            onChange={e => onChange(e.target.value)}
            value={search}
          />
        </Flex>
        <Flex style={{ paddingTop: 10 }} vertical>
          {products?.map(item => (
            <StyledListItem
              key={item.id}
              onClick={() => {
                dispatch(setLocationModalType('product'));
                navigate(`/locations?id=${item.id}`);
              }}
            >
              <Flex vertical>
                <Paragraph style={{ margin: 0 }}>
                  {`${t('product.name')}: `}
                  {highlightText(item.name || '', search)}
                </Paragraph>
                <Paragraph style={{ margin: 0 }}>
                  {`${t('product.barcode')}: `}
                  {highlightText(item.barcode, search)}
                </Paragraph>
                <Paragraph style={{ margin: 0 }}>
                  {`${t('product.code')}: `}
                  {highlightText(item.code, search)}
                </Paragraph>
              </Flex>
              <Button type="primary">{t('locations.view_product')}</Button>
            </StyledListItem>
          ))}
        </Flex>
      </Modal>
    </>
  );
};

export default SearchProductModal;
