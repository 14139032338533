import { Card, Typography, Row, Col, Flex, Empty } from 'antd';
import {
  selectAnalyticsData,
  selectAnalyticsLoading
} from 'app/features/analytics/selectors';
import { getAllAnalytics } from 'app/features/analytics/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';
import OrderByDate from 'components/OrderByDate';
import CustomersOrdersChart from 'components/CustomersOrdersChart';
import CustomerTable from './CustomerTable';

const Dashboard = () => {
  const { Title } = Typography;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectAnalyticsLoading());
  const analytics = useAppSelector(selectAnalyticsData());

  const sorted = Object.entries(analytics?.customers_order || {}).map(
    ([key, value]) => ({
      customer: key,
      orders: value as number
    })
  );

  useEffect(() => {
    dispatch(getAllAnalytics());
  }, [dispatch]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Flex gap={24} vertical>
      <Title style={{ whiteSpace: 'nowrap' }} level={2}>
        {t('common.dashboard')}
      </Title>
      <Row gutter={[24, 24]}>
        <Col span={24}>
          <Card>
            <Typography.Title level={4} style={{ marginBottom: 20 }}>
              {t('dashboard.orders_by_dates')}
            </Typography.Title>
            {analytics?.order_month ? (
              <OrderByDate
                color="#4F84E8"
                data={Object.entries(analytics?.order_month || {})?.map(
                  ([key, value]) => ({
                    Date: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                    Count: (value as number) || 0
                  })
                )}
              />
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <Col span={18}>
          <Card>
            <Typography.Title style={{ marginBottom: 20 }} level={4}>
              {t('dashboard.customers_order')}
            </Typography.Title>
            {analytics?.customers_order ? (
              <CustomersOrdersChart
                dataOrders={Object.entries(
                  analytics?.customers_order || {}
                )?.map(([key, value]) => {
                  return {
                    customer: `${key.charAt(0).toUpperCase()}${key.slice(1)}`,
                    orders: (value as number) || 0
                  };
                })}
              />
            ) : (
              <Empty />
            )}
          </Card>
        </Col>
        <CustomerTable
          data={sorted}
          customerCount={analytics?.customers_count}
          orderCount={analytics?.orders_count}
        />
      </Row>
    </Flex>
  );
};

export default Dashboard;
