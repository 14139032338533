import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import {
  LocationCreateParams,
  LocationUpdateParams,
  LocationsState
} from './types';

const initialState: LocationsState = {
  locations: [],
  locationById: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  selectedLocationId: null
};

export const getLocations = createAsyncThunk(
  'locations/getLocations',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('rows');

      return res.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getLocationById = createAsyncThunk(
  'location/getLocationById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.get(`/rows/${id}`);

      return dispatch(setLocationById(response.data.data));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createLocation = createAsyncThunk(
  'locations/createLocations',
  async (
    { parent_id, ...data }: LocationCreateParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.post('/rows', {
        ...data,
        parent_id
      });

      return dispatch(getLocations());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateLocation = createAsyncThunk(
  'location/updateLocation',
  async (
    { id, ...data }: LocationUpdateParams,
    { dispatch, rejectWithValue }
  ) => {
    try {
      await client.put(`/rows/${id}`, {
        ...data
      });

      return dispatch(getLocations());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const deleteLocations = createAsyncThunk(
  'locations/deleteLocations',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await client.delete(`/rows/${id}`);

      return dispatch(getLocations());
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationById: (state, action) => {
      state.locationById = action.payload;
    },
    setSelectedLocationId: (state, action: PayloadAction<number>) => {
      state.selectedLocationId = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getLocations.pending, state => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        state.locations = action.payload;
        state.loading = false;
      })
      .addCase(getLocations.rejected, state => {
        state.loading = false;
      })
      .addCase(getLocationById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getLocationById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getLocationById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(deleteLocations.pending, state => {
        state.loadingAction = true;
      })
      .addCase(deleteLocations.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(deleteLocations.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(createLocation.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createLocation.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createLocation.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateLocation.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateLocation.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateLocation.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setLocationById, setSelectedLocationId } =
  locationsSlice.actions;
export default locationsSlice.reducer;
