import { message } from 'antd';
import { forgotPassword } from 'app/features/auth/slice';
import { useAppDispatch } from 'app/hooks';
import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledButton } from './styled';

interface IProps {
  email: string;
}

const ResendButton: FC<IProps> = ({ email }) => {
  // translation
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  // states
  const [seconds, setSeconds] = useState(60);
  // refs
  const interval = useRef<NodeJS.Timeout | null>(null);

  useEffect(
    () => () => {
      if (interval.current) {
        clearInterval(interval.current);
      }
    },
    []
  );

  useEffect(() => {
    if (seconds <= 0) {
      if (interval.current) {
        clearInterval(interval.current);
      }

      setSeconds(60);
    }
  }, [seconds]);

  const onClick = useCallback(async () => {
    if (seconds !== 60) return;

    const res = await dispatch(forgotPassword({ email }));

    if (res.meta.requestStatus === 'fulfilled') {
      message.success(t('auth.successfully_sent'));
      setSeconds(59);
      interval.current = setInterval(() => {
        setSeconds(prev => prev - 1);
      }, 1000);
    }
  }, [dispatch, email, seconds, t]);

  const renderTime = () =>
    seconds !== 60 && `00:${seconds > 9 ? seconds : `0${seconds}`}`;

  return (
    <StyledButton type="link" onClick={onClick} disabled={seconds !== 60}>
      {t('auth.reset_email_resend')} {renderTime()}
    </StyledButton>
  );
};

export default memo(ResendButton);
