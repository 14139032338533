import { Flex, Typography } from 'antd';
import { selectCustomerByIdLoading } from 'app/features/customers/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { selectManagerById } from 'app/features/managers/selectors';
import { getManagerById, setManagerById } from 'app/features/managers/slice';

import Loading from 'components/Loading';
import ManagerInfo from './components/ManagerInfo';
import ManageCustomers from './components/ManageCustomers';

const { Title } = Typography;

const ManagerDetails: FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const managerById = useAppSelector(selectManagerById());
  const loadingById = useAppSelector(selectCustomerByIdLoading());

  useEffect(() => {
    if (id) {
      dispatch(getManagerById(Number(id)));
    }

    return () => {
      dispatch(setManagerById(null));
    };
  }, [dispatch, id]);

  if (loadingById) {
    return <Loading />;
  }

  return (
    <Flex vertical style={{ width: '100%' }} gap={48}>
      <Flex gap={24}>
        <ArrowLeftOutlined
          onClick={() => navigate('/managers')}
          style={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
        />
        <Title level={2}>
          {managerById?.first_name} {managerById?.last_name}
        </Title>
      </Flex>
      <ManagerInfo />
      <ManageCustomers />
    </Flex>
  );
};

export default ManagerDetails;
