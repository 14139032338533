import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DatePicker, Flex, Input, Typography } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getAllManagers } from 'app/features/managers/slice';
import { selectManagersData } from 'app/features/managers/selectors';
import { ORDERS_PAYMENT_TYPES, ORDERS_STATUS_TYPES } from 'constant/order';
import dayjs from 'dayjs';
import { setPage } from 'app/features/managersOrders/slice';

import {
  DatePickerWrapper,
  SearchContainer,
  StyledHeader,
  StyledSelect
} from './styled';

const { Title } = Typography;
const { Search } = Input;

const ManagerOrdersHeader = () => {
  const { t } = useTranslation();
  const managersData = useAppSelector(selectManagersData());

  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const onSearch = e => {
    const searchValue = e.target.value;
    dispatch(setPage(0));

    if (searchValue) {
      searchParams.set('search', searchValue);
      setSearchParams(searchParams);
    } else {
      searchParams.delete('search');
      setSearchParams(searchParams);
    }
  };

  const onSelect = (result: string, type: string) => {
    dispatch(setPage(0));

    if (result) {
      searchParams.set(type, result);
      setSearchParams(searchParams);
    } else {
      searchParams.delete(type);
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    dispatch(
      getAllManagers({
        page: 0,
        limit: 9999
      })
    );
  }, [dispatch]);

  return (
    <StyledHeader>
      <Title style={{ whiteSpace: 'nowrap' }} level={2}>
        {t('common.manager_orders')}
      </Title>
      <Flex gap={16} vertical style={{ width: '100%' }} align="flex-end">
        <SearchContainer>
          <Search
            style={{ width: '100%' }}
            value={searchParams.get('search') || undefined}
            placeholder={t('orders.orders_search_placeholder')}
            onChange={onSearch}
          />
        </SearchContainer>
        <Flex gap={16} style={{ width: '100%' }} justify="space-between">
          <StyledSelect
            allowClear
            value={Number(searchParams.get('manager')) || undefined}
            onChange={e => onSelect(e as string, 'manager')}
            placeholder={t('orders.select_manager')}
            options={managersData?.results?.map(item => ({
              value: item.id,
              label: `${item.first_name} ${item.last_name}`
            }))}
          />
          <DatePickerWrapper>
            <DatePicker
              value={
                searchParams.get('date')
                  ? dayjs(searchParams.get('date'))
                  : undefined
              }
              placeholder={t('orders.select_date')}
              style={{ width: '100%' }}
              onChange={date => {
                if (date) {
                  onSelect(dayjs(date).format('YYYY-MM-DD'), 'date');
                } else {
                  onSelect('', 'date');
                }
              }}
            />
          </DatePickerWrapper>
          <StyledSelect
            allowClear
            value={Number(searchParams.get('status')) || undefined}
            onChange={e => onSelect(e as string, 'status')}
            placeholder={t('orders.select_status')}
            options={ORDERS_STATUS_TYPES.map(item => ({
              value: item.value,
              label: t(`orders.${item.label}`)
            }))}
          />
          <StyledSelect
            allowClear
            value={Number(searchParams.get('payment')) || undefined}
            onChange={e => onSelect(e as string, 'payment')}
            placeholder={t('orders.select_payment')}
            options={ORDERS_PAYMENT_TYPES.map(item => ({
              value: item.value,
              label: t(`orders.${item.label}`)
            }))}
          />
        </Flex>
      </Flex>
    </StyledHeader>
  );
};

export default ManagerOrdersHeader;
