import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './resources/en.json';
import hy from './resources/hy.json';
import ru from './resources/ru.json';

const locale = localStorage.getItem('locale') || 'hy';

i18n.use(initReactI18next).init({
  resources: {
    hy: {
      translation: hy
    },
    en: {
      translation: en
    },
    ru: {
      translation: ru
    }
  },
  lng: locale,
  fallbackLng: locale,
  interpolation: {
    escapeValue: false
  }
});
