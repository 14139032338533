import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { selectManagersLimit } from './selectors';
import {
  ICreateManager,
  IActionCustomer,
  ManagersState,
  UpdateManager
} from './types';

const initialState: ManagersState = {
  managers: { results: [], total: 0 },
  managerById: null,
  loading: false,
  loadingCustomerAction: false,
  loadingById: false,
  loadingAction: false,
  limit: 10
};

interface GetAllManagersParams {
  search?: string | null;
  page?: number | null;
  limit?: number;
}

export const getAllManagers = createAsyncThunk(
  'managers/getAllManagers',
  async (
    params: GetAllManagersParams | undefined,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const limit = selectManagersLimit()(state);
      const res = await client.get('managers', {
        params: {
          page: params?.page,
          limit: params?.limit || limit,
          name: params?.search
        }
      });

      return res.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createManager = createAsyncThunk(
  'managers/createManager',
  async (body: ICreateManager, { rejectWithValue }) => {
    try {
      await client.post('managers', body);
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateManager = createAsyncThunk(
  'managers/updateManager',
  async ({ id, ...data }: UpdateManager, { rejectWithValue }) => {
    try {
      await client.put(`managers/${id}`, {
        ...data
      });
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateManagerPassword = createAsyncThunk(
  'managers/updateManagerPassword',
  async (
    { id, password }: { id: number; password: string },
    { rejectWithValue }
  ) => {
    try {
      await client.put(`managers/update-pass/${id}`, {
        password
      });
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getManagerById = createAsyncThunk(
  'managers/getManagerById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.get(`/managers/${id}`);

      return dispatch(setManagerById(response.data.data));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const removeCustomerFromManager = createAsyncThunk(
  'managers/removeCustomerFromManager',
  async (body: IActionCustomer, { rejectWithValue }) => {
    try {
      await client.post('managers/delete-attach-customer', body);
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const addCustomerFromManager = createAsyncThunk(
  'managers/addCustomerFromManager',
  async (body: IActionCustomer, { rejectWithValue }) => {
    try {
      await client.post('managers/attach-customer', body);
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const managersSlice = createSlice({
  name: 'managers',
  initialState,
  reducers: {
    setManagerById: (state, action) => {
      state.managerById = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllManagers.pending, state => {
        state.loading = true;
      })
      .addCase(getAllManagers.fulfilled, (state, action) => {
        state.managers = action.payload;
        state.loading = false;
      })
      .addCase(getAllManagers.rejected, state => {
        state.loading = false;
      })
      .addCase(createManager.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createManager.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createManager.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getManagerById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getManagerById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getManagerById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(updateManager.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateManager.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateManager.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateManagerPassword.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateManagerPassword.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateManagerPassword.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(removeCustomerFromManager.pending, state => {
        state.loadingCustomerAction = true;
      })
      .addCase(removeCustomerFromManager.fulfilled, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(removeCustomerFromManager.rejected, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(addCustomerFromManager.pending, state => {
        state.loadingCustomerAction = true;
      })
      .addCase(addCustomerFromManager.fulfilled, state => {
        state.loadingCustomerAction = false;
      })
      .addCase(addCustomerFromManager.rejected, state => {
        state.loadingCustomerAction = false;
      });
  }
});

export const { setManagerById } = managersSlice.actions;
export default managersSlice.reducer;
