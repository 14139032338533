import { App, Button, Form, Input, Modal } from 'antd';
import { selectManagersLoadingAction } from 'app/features/managers/selectors';
import {
  getAllManagers,
  updateManagerPassword
} from 'app/features/managers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  id: number | null;
}

const PasswordModal: FC<IProps> = ({ isOpen, onClose, id }) => {
  const { t } = useTranslation();
  const loadingAction = useAppSelector(selectManagersLoadingAction());
  const [searchParams] = useSearchParams();
  const { modal } = App.useApp();

  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
  };

  const onSave = async (values: { password: string }) => {
    await form.validateFields();
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    const pageValue = Number(searchParams.get('page'));

    const page = pageValue ? pageValue - 1 : 0;

    if (id) {
      return dispatch(
        updateManagerPassword({
          id: id,
          ...values
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
          dispatch(
            getAllManagers({
              search: searchValue,
              page: page
            })
          );
          modal.success({ title: t('managers.manager_password_update') });
        }
      });
    }
  };

  return (
    <Modal
      title={t('managers.change_password')}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={async () => handleCloseModal()}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSave} layout="vertical" size="large">
        <Form.Item
          name="password"
          label={t('managers.password')}
          rules={[
            {
              required: true
            },
            {
              min: 8,
              message: t('validation.password_warning')
            }
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('managers.password_placeholder')}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default PasswordModal;
