import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { App as AntApp } from 'antd';

import App from './App';
import { store } from './app/store';
import 'theme/index.css';
import 'theme/vars.css';
import 'locales';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <Suspense>
        <AntApp>
          <App />
        </AntApp>
      </Suspense>
    </Provider>
  </BrowserRouter>
);
