import {
  AppstoreAddOutlined,
  CarOutlined,
  HomeOutlined,
  IdcardOutlined,
  PieChartOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserSwitchOutlined
} from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';

const MenuSider = () => {
  const router = useLocation();
  const { t } = useTranslation();
  const menuSection = [
    {
      label: <Link to={'/'}>{t('common.dashboard')}</Link>,
      key: '/',
      icon: <PieChartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/locations'}>{t('common.locations')}</Link>,
      key: '/locations',
      icon: <AppstoreAddOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/products'}>{t('common.products')}</Link>,
      key: '/products',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/customers'}>{t('common.customers')}</Link>,
      key: '/customers',
      icon: <TeamOutlined style={{ fontSize: 20 }} />
    },
    {
      label: <Link to={'/employees'}>{t('common.employees')}</Link>,
      key: '/employees',
      icon: <UserSwitchOutlined style={{ fontSize: 20 }} />
    },
    {
      label: t('common.orders'),
      key: '/orders',
      icon: <ShoppingCartOutlined style={{ fontSize: 20 }} />,
      children: [
        {
          key: '/orders/warehouse',
          label: (
            <Link to={'/orders/warehouse'}>{t('common.warehouse_orders')}</Link>
          ),
          icon: <HomeOutlined style={{ fontSize: 20 }} />
        },
        {
          key: '/orders/managers',
          label: (
            <Link to={'/orders/managers'}>{t('common.manager_orders')}</Link>
          ),
          icon: <CarOutlined style={{ fontSize: 20 }} />
        }
      ]
    },
    {
      label: <Link to={'/managers'}>{t('common.managers')}</Link>,
      key: '/managers',
      icon: <IdcardOutlined style={{ fontSize: 20 }} />
    }
  ];

  const getSelectedKeys = (pathname: string) => {
    const allMenuItems = menuSection.flatMap(item =>
      item.children ? [item, ...item.children] : [item]
    );

    const foundItem = allMenuItems.find(item => item.key === pathname);

    return foundItem ? [foundItem.key] : [menuSection[0].key];
  };

  return (
    <Menu
      defaultSelectedKeys={getSelectedKeys(router.pathname)}
      selectedKeys={getSelectedKeys(router.pathname)}
      mode="inline"
      theme="dark"
      items={menuSection}
    />
  );
};

export default MenuSider;
