import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectManagerInfoLoading,
  selectManagerOrderInfo
} from 'app/features/managersOrders/selectors';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { getManagerOrderInfoById } from 'app/features/managersOrders/slice';
import { Divider, Flex, Table, Tag, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { ORDERS_PAYMENT_TYPES, ORDERS_STATUS_TYPES } from 'constant/order';
import { ColumnsType } from 'antd/lib/table';
import { IManagersOrders } from 'app/features/managersOrders/types';

import OrderProductList from './components/OrderProductList';

const { Paragraph } = Typography;

const ManagerOrderDetails = () => {
  const managerInfoLoading = useAppSelector(selectManagerInfoLoading());
  const managerInfo = useAppSelector(selectManagerOrderInfo());

  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const columns: ColumnsType<IManagersOrders> = [
    {
      title: t('orders.customer_name'),
      dataIndex: 'customer_name',
      key: 'customer_name'
    },
    {
      title: t('orders.manager_name'),
      dataIndex: 'manager_name',
      key: 'manager_name'
    },
    {
      title: t('orders.total_price'),
      dataIndex: 'total_price',
      key: 'total_price',
      render: price => (
        <Paragraph strong style={{ fontSize: 16, marginBottom: 0 }}>
          {price}
        </Paragraph>
      )
    },
    {
      title: t('orders.payment'),
      dataIndex: 'payment',
      key: 'payment',
      render: item => {
        const payment = ORDERS_PAYMENT_TYPES.find(
          e => String(e.value) === item
        );

        return (
          <Tag color={payment?.color}>{t(`orders.${payment?.label}`)}</Tag>
        );
      }
    },
    {
      title: t('orders.status'),
      dataIndex: 'status',
      key: 'status',
      render: item => {
        const status = ORDERS_STATUS_TYPES.find(e => String(e.value) === item);

        return <Tag color={status?.color}>{t(`orders.${status?.label}`)}</Tag>;
      }
    }
  ];

  useEffect(() => {
    if (id) {
      dispatch(getManagerOrderInfoById(Number(id)));
    }
  }, [dispatch, id]);

  return (
    <Flex vertical style={{ width: '100%' }} gap={24}>
      <ArrowLeftOutlined
        onClick={() => navigate('/orders/managers')}
        style={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
      />
      <Flex vertical gap={16}>
        <Divider orientation="left">{t('orders.order_info')}</Divider>
        <Table
          loading={managerInfoLoading}
          columns={columns}
          title={() => (
            <Typography.Paragraph style={{ marginBottom: 0 }} strong>
              {dayjs(managerInfo?.created_at).format('DD MMMM, YYYY')}
            </Typography.Paragraph>
          )}
          dataSource={managerInfo ? [managerInfo] : undefined}
          bordered
          pagination={false}
        />
        <OrderProductList />
      </Flex>
    </Flex>
  );
};

export default ManagerOrderDetails;
