import { useState } from 'react';
import { Flex, Tooltip, Typography, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';

const CopyableText = ({ text }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true);
        notification.success({
          placement: 'top',
          closable: false,
          duration: 2,
          message: t('common.text_copied')
        });
      })
      .catch(() => {
        setCopied(false);
        notification.error({
          message: t('common.copy_failed'),
          description: t('common.copy_error'),
          placement: 'topRight'
        });
      });
  };

  if (!text) {
    return null;
  }

  setTimeout(() => {
    setCopied(false);
  }, 2500);

  return (
    <Tooltip title={t('common.copy')} placement="topLeft">
      <Flex
        justify="space-between"
        align="center"
        style={{ cursor: 'pointer' }}
        onClick={handleCopy}
      >
        <Typography.Paragraph>{text}</Typography.Paragraph>
        {copied ? (
          <CheckOutlined style={{ color: '#52c41a' }} />
        ) : (
          <CopyOutlined style={{ color: '#1677ff' }} />
        )}
      </Flex>
    </Tooltip>
  );
};

export default CopyableText;
