import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { useTranslation } from 'react-i18next';
import './App.css';
import AllRoutes from 'routes';

import { getFormLang } from 'utils/helpers';
import { validateMessages } from 'utils/validation';
import { customTheme } from './theme';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const lang = localStorage.getItem('locale');

    if (lang) {
      i18n.changeLanguage(lang);
    }
  }, [i18n]);

  return (
    <div className="App">
      <ConfigProvider
        form={{ validateMessages: validateMessages(t) }}
        locale={getFormLang(i18n.language)}
        theme={customTheme}
      >
        <AllRoutes />
      </ConfigProvider>
    </div>
  );
}

export default App;
