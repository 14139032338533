import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import Login from 'pages/Auth/Login';
import Auth from 'pages/Auth';
import ForgotPassword from 'pages/Auth/ForgotPassword';
import NewPassword from 'pages/Auth/NewPassword';

const publicRoutes: RouteObject[] = [
  {
    path: '/',
    element: <Auth />,
    children: [
      {
        path: '',
        element: <Navigate to="login" replace />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />
      },
      {
        path: 'new-password',
        element: <NewPassword />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/login" replace />
  }
];

const PublicRoutes = () => {
  const routers = useRoutes(publicRoutes);

  return routers;
};

export default PublicRoutes;
