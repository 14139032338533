import en from 'antd/lib/locale/en_US';
import de from 'antd/lib/locale/de_DE';

export const getFormLang = (lang: string) => {
  switch (lang) {
    case 'en':
      return en;
    case 'de':
      return de;
    default:
      return en;
  }
};

export const _debounce = (delay = 400) => {
  let timer: NodeJS.Timeout | null = null;

  return (cb: () => void) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(cb, delay);
  };
};
