import { Select } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 20px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 724px;
  .select-wrapper {
    min-width: 241px;
  }
`;

export const DatePickerWrapper = styled.div`
  width: calc(100% / 4);
`;

export const StyledSelect = styled(Select)`
  width: calc(100% / 4);
`;
