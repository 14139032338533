import { App, Button, Form, Input, Modal } from 'antd';
import {
  selectLocationById,
  selectLocationByIdLoading,
  selectLocationsLoadingAction
} from 'app/features/locations/selectors';
import {
  createLocation,
  setLocationById,
  updateLocation
} from 'app/features/locations/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Loading from 'components/Loading';

interface IProps {
  isOpen: boolean;
  parentId: null | number;
  onClose: () => void;
}

const LocationModal: FC<IProps> = ({ isOpen, parentId, onClose }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  // selectors
  const loadingAction = useAppSelector(selectLocationsLoadingAction());
  const loadingById = useAppSelector(selectLocationByIdLoading());
  const location = useAppSelector(selectLocationById());
  const locationById = useAppSelector(selectLocationById());
  const { t } = useTranslation();
  const { modal } = App.useApp();

  const handleCloseModal = () => {
    form.resetFields();
    onClose();
    dispatch(setLocationById(null), locationById);
  };

  const modalTitle = () => {
    if (locationById?.id) {
      return t('locations.update_location');
    } else if (parentId) {
      return t('locations.create_sub_location');
    }

    return t('locations.create_location');
  };

  const onSave = async (values: { name: string }) => {
    if (locationById?.id) {
      await dispatch(
        updateLocation({
          name: values.name,
          id: locationById?.id
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
          modal.success({ title: t('locations.success_location_create') });
        }
      });
    } else {
      await dispatch(
        createLocation({
          name: values.name,
          parent_id: parentId
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          handleCloseModal();
          modal.success({ title: t('locations.success_location_update') });
        }
      });
    }
  };

  useEffect(() => {
    if (locationById?.id) {
      form.setFieldsValue({
        name: location?.name
      });
    }
  }, [form, location?.name, locationById?.id]);

  if (loadingById) {
    return <Loading />;
  }

  return (
    <Modal
      title={modalTitle()}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={handleCloseModal}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSave} layout="vertical" size="large">
        <Form.Item
          name="name"
          label={t('locations.location_name')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('locations.name_placeholder')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(LocationModal);
