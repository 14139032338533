import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Upload
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import {
  createManager,
  getAllManagers,
  setManagerById
} from 'app/features/managers/slice';
import { ICreateManager } from 'app/features/managers/types';
import { selectManagersLoadingAction } from 'app/features/managers/selectors';
import { UploadOutlined } from '@ant-design/icons';

import { useUpload } from 'hooks/useUpload';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateManager: FC<IProps> = ({ isOpen, onClose }) => {
  // form
  const [form] = Form.useForm();
  // dispatch
  const dispatch = useAppDispatch();
  const { onUploadFile, clearUploadQueue } = useUpload();
  // selectors
  const loadingAction = useAppSelector(selectManagersLoadingAction());
  const [availability, setAvailability] = useState(true);
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { modal } = App.useApp();
  const { Dragger } = Upload;

  const handleCloseModal = () => {
    form.resetFields();
    clearUploadQueue();
    dispatch(setManagerById(null));
    onClose();
  };

  const onSave = async (values: ICreateManager) => {
    await form.validateFields();
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    const pageValue = Number(searchParams.get('page'));

    const page = pageValue ? pageValue - 1 : 0;

    return dispatch(
      createManager({
        ...values,
        file_id: values.file_id
          ? values.file_id[0]?.response.data.data.id
          : undefined,
        active: availability
      })
    ).then(res => {
      if (res.meta.requestStatus === 'fulfilled') {
        handleCloseModal();
        dispatch(
          getAllManagers({
            search: searchValue,
            page: page
          })
        );
        modal.success({ title: t('managers.manager_was_create') });
      }
    });
  };

  const onChangeAvailability = (e: RadioChangeEvent) => {
    setAvailability(e.target.value);
  };

  return (
    <Modal
      title={t('managers.create_manager')}
      open={isOpen}
      onCancel={handleCloseModal}
      footer={[
        <Button key="cancel" onClick={async () => handleCloseModal()}>
          {t('common.cancel')}
        </Button>,
        <Button
          key="save"
          type="primary"
          onClick={form.submit}
          loading={loadingAction}
        >
          {t('common.save')}
        </Button>
      ]}
    >
      <Form form={form} onFinish={onSave} layout="vertical" size="large">
        <Form.Item
          name="first_name"
          label={t('managers.first_name')}
          rules={[{ required: true }]}
        >
          <Input placeholder={t('managers.first_name_placeholder')} />
        </Form.Item>
        <Form.Item
          rules={[{ required: true }]}
          name="last_name"
          label={t('managers.last_name')}
        >
          <Input placeholder={t('managers.last_name_placeholder')} />
        </Form.Item>
        <Form.Item
          name="email"
          label={t('managers.email')}
          rules={[{ type: 'email' }]}
          messageVariables={{
            label: t('validation.input_is_not_valid_email')
          }}
        >
          <Input
            autoComplete="new-email"
            placeholder={t('managers.email_placeholder')}
          />
        </Form.Item>
        <Form.Item
          name="phone"
          label={t('managers.phone')}
          rules={[
            { required: true },
            {
              // eslint-disable-next-line no-useless-escape
              pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
              message: t('validation.enter_phone_number')
            }
          ]}
        >
          <Input placeholder={t('managers.phone_placeholder')} />
        </Form.Item>
        <Form.Item
          name="password"
          label={t('managers.password')}
          rules={[
            {
              required: true
            },
            {
              min: 8,
              message: t('validation.password_warning')
            }
          ]}
        >
          <Input.Password
            autoComplete="new-password"
            placeholder={t('managers.password_placeholder')}
          />
        </Form.Item>
        <Form.Item
          label={t('managers.manager_image')}
          name="file_id"
          valuePropName="fileList"
          getValueFromEvent={e => e.fileList || []}
        >
          <Dragger
            accept="image/*"
            customRequest={onUploadFile}
            maxCount={1}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>
              {t('managers.upload_image')}
            </Button>
          </Dragger>
        </Form.Item>
        <Radio.Group onChange={onChangeAvailability} value={availability}>
          <Radio value={true}>{t('managers.active')}</Radio>
          <Radio value={false}>{t('managers.inactive')}</Radio>
        </Radio.Group>
      </Form>
    </Modal>
  );
};

export default memo(CreateManager);
