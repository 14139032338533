import { Button } from 'antd';
import styled from 'styled-components';

export const StyledForm = styled.div`
  max-width: 600px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100vh;

  > div {
    background-color: white;
    padding: 50px;
    box-shadow: 12px 12px 15px -9px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
`;

export const Content = styled.div`
  background-color: #efefef;
`;

export const AuthButton = styled(Button)`
  min-width: 200px;
  padding-inline: 40px;
`;
