import { Flex, Form, Input, Typography } from 'antd';
import { selectAuthLoading } from 'app/features/auth/selectors';
import { postLogin } from 'app/features/auth/slice';
import { ILogin } from 'app/features/auth/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { AuthButton } from '../styled';

const { Title } = Typography;

const Login = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoading = useAppSelector(selectAuthLoading());

  const onFinish = async (values: ILogin) => {
    const res = await dispatch(postLogin(values));

    if (res.meta.requestStatus === 'fulfilled') {
      navigate('/');
    }
  };

  return (
    <>
      <Title level={2} style={{ marginBottom: 24 }}>
        {t('auth.welcome')}
      </Title>
      <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
        <Flex vertical>
          <Form.Item
            name="email"
            rules={[{ required: true }, { type: 'email' }]}
            messageVariables={{ label: t('auth.email') }}
          >
            <Input placeholder={t('auth.email')} />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true }]}
            messageVariables={{ label: t('auth.password') }}
            style={{ marginBottom: 0 }}
          >
            <Input.Password placeholder={t('auth.password')} />
          </Form.Item>
          <Flex justify="flex-end" style={{ marginTop: '8px' }}>
            <Link style={{ color: '#2D2C4C' }} to="/forgot-password">
              {t('auth.forgot_password')}
            </Link>
          </Flex>

          <Form.Item style={{ marginTop: '24px', marginBottom: 0 }}>
            <AuthButton
              type="primary"
              htmlType="submit"
              shape="round"
              loading={isLoading}
            >
              {t('auth.login')}
            </AuthButton>
          </Form.Item>
        </Flex>
      </Form>
    </>
  );
};

export default Login;
