import { Button, Flex, Typography } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledHeader } from 'pages/Locations/styled';

interface IPageHeader {
  title: string;
  isOpenModal: () => void;
}

const { Title } = Typography;

const LocationsHeader: FC<IPageHeader> = ({ title, isOpenModal }) => {
  const { t } = useTranslation();

  return (
    <StyledHeader>
      <Flex justify="space-between">
        <Title level={2}>{title}</Title>
        <Button onClick={isOpenModal}>{t('locations.create_location')}</Button>
      </Flex>
    </StyledHeader>
  );
};

export default LocationsHeader;
