import React, { useState } from 'react';
import { Flex, Layout } from 'antd';

import Header from './Header';
import {
  LayoutStyled,
  MenuFoldIcon,
  MenuUnFoldIcon,
  SiderStyled,
  SiderTop,
  StyledLayoutContent
} from './styled';
import MenuSider from 'components/MainLayout/MenuSider';

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <LayoutStyled>
      <Header />
      <Layout>
        <SiderStyled
          trigger={null}
          collapsible
          collapsed={collapsed}
          collapsedWidth={72}
          width={250}
        >
          <SiderTop $collapsed={collapsed}>
            {collapsed ? (
              <MenuUnFoldIcon onClick={() => setCollapsed(!collapsed)} />
            ) : (
              <MenuFoldIcon onClick={() => setCollapsed(!collapsed)} />
            )}
          </SiderTop>
          <MenuSider />
        </SiderStyled>
        <Flex justify="flex-end" style={{ width: '100%' }}>
          <StyledLayoutContent $collapsed={collapsed}>
            <Layout.Content>{children}</Layout.Content>
          </StyledLayoutContent>
        </Flex>
      </Layout>
    </LayoutStyled>
  );
};

export default MainLayout;
