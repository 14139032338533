import { RootState } from 'app/store';

export const selectCustomersData = () => (state: RootState) =>
  state.customers.customers;

export const selectCustomersLoading = () => (state: RootState) =>
  state.customers.loading;
export const selectCustomersLimit = () => (state: RootState) =>
  state.customers.limit;

export const selectCustomersLoadingAction = () => (state: RootState) =>
  state.customers.loadingAction;

export const selectCustomerById = () => (state: RootState) =>
  state.customers.customerById;

export const selectCustomerByIdLoading = () => (state: RootState) =>
  state.customers.loadingById;

export const selectOrderByCustomersData = () => (state: RootState) =>
  state.customers.orderByCustomer;

export const selectOrderByIdPage = () => (state: RootState) =>
  state.customers.customerById;

export const selectOrderByIdLimit = () => (state: RootState) =>
  state.customers.orderLimit;
