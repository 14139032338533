import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Input, Typography } from 'antd';
import { selectAuthLoading } from 'app/features/auth/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { forgotPassword } from 'app/features/auth/slice';
import { useState } from 'react';
import { IForgotPassword } from 'app/features/auth/types';

import { StyledDescription } from './styled';
import { AuthButton } from '../styled';
import ResendButton from '../ResendButton';

const { Title } = Typography;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAuthLoading());
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const onFinish = async (values: IForgotPassword) => {
    const res = await dispatch(forgotPassword(values));

    if (res.meta.requestStatus === 'fulfilled') {
      setEmail(values.email);
    }
  };

  return (
    <>
      {email ? (
        <>
          <Title level={2} style={{ marginBottom: 24 }}>
            {t('auth.reset_password')}
          </Title>
          <StyledDescription>
            {t('auth.reset_email_description')}
            <ResendButton email={email} />
          </StyledDescription>
          <Button
            type="primary"
            onClick={() => navigate('/login')}
            shape="round"
            size="large"
            style={{ paddingInline: 40, marginTop: 12 }}
            block
          >
            {t('auth.login')}
          </Button>
        </>
      ) : (
        <>
          <Title level={2} style={{ marginBottom: 24 }}>
            {t('auth.reset_password')}
          </Title>
          <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
            <Flex vertical>
              <Form.Item
                name="email"
                rules={[{ required: true }, { type: 'email' }]}
                messageVariables={{ label: t('auth.email') }}
              >
                <Input placeholder={t('auth.email')} />
              </Form.Item>

              <Form.Item style={{ marginTop: '16px' }}>
                <AuthButton
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  block
                  loading={isLoading}
                >
                  {t('auth.submit')}
                </AuthButton>
              </Form.Item>
              <Flex>
                <Link to="/login" style={{ color: '#2D2C4C' }}>
                  <ArrowLeftOutlined style={{ marginRight: 12 }} />
                  {t('auth.back_login')}
                </Link>
              </Flex>
            </Flex>
          </Form>
        </>
      )}
    </>
  );
};

export default ForgotPassword;
