import { FC } from 'react';
import { useAppSelector } from 'app/hooks';
import { selectUserIsLoggedIn } from 'app/features/auth/selectors';

import PublicRoutes from './Public';
import PrivateRoutes from './Private';

const Routes: FC = () => {
  const isLoggedIn = useAppSelector(selectUserIsLoggedIn());

  if (isLoggedIn) {
    return <PrivateRoutes />;
  }

  return <PublicRoutes />;
};

export default Routes;
