import { useRef } from 'react';
import { UploadRequestOption } from 'rc-upload/lib/interface';
import { RcFile } from 'antd/es/upload/interface';
import { client } from 'api'; // Import your API client

type UseUploadReturnType = {
  onUploadFile: (option: UploadRequestOption) => void;
  clearUploadQueue: () => void;
};

export const useUpload = (): UseUploadReturnType => {
  const timer = useRef<NodeJS.Timeout>();

  const uploadRequests = useRef<UploadRequestOption[]>([]);

  const onUploadFile = async (e: UploadRequestOption) => {
    if (timer.current) {
      clearTimeout(timer.current);
    }

    uploadRequests.current.push(e);

    timer.current = setTimeout(async () => {
      await Promise.all(
        uploadRequests.current.map(async request => {
          const { file, onProgress, onSuccess, onError } = request;

          const formData = new FormData();

          const uniqueFileName = `${Math.floor(Math.random() * 1000)}_${
            (file as RcFile).name
          }`;

          formData.append(
            'file',
            new File([file], uniqueFileName, { type: (file as RcFile).type })
          );

          try {
            const res = await client.post('/files', formData, {
              onUploadProgress(progress) {
                onProgress?.(progress);
              }
            });

            onSuccess?.(res);
          } catch (err) {
            onError?.(err as ProgressEvent<EventTarget>);
          }
        })
      );

      uploadRequests.current = [];
    }, 50);
  };

  const clearUploadQueue = () => {
    uploadRequests.current = [];
  };

  return { onUploadFile, clearUploadQueue };
};
