import { Empty, Flex, Pagination, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectOrderByIdLimit,
  selectOrderByCustomersData,
  selectCustomerByIdLoading
} from 'app/features/customers/selectors';
import { getOrdersByCustomerId } from 'app/features/customers/slice';
import { OrderProducts } from 'app/features/customers/types';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import Loading from 'components/Loading';

interface IProps {
  date: string | null;
}

const OrderByCustomer: FC<IProps> = ({ date }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { id } = useParams();
  const limit = useAppSelector(selectOrderByIdLimit());
  const orders = useAppSelector(selectOrderByCustomersData());
  const loading = useAppSelector(selectCustomerByIdLoading());
  const [searchParams, setSearchParams] = useSearchParams();

  const changePage = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };

  const columns: ColumnsType<OrderProducts> = [
    {
      title: t('orders.product_code'),
      dataIndex: ['products', 'code'],
      key: 'code'
    },
    {
      title: t('orders.product_barcode'),
      dataIndex: ['products', 'barcode'],
      key: 'barcode'
    },
    {
      title: t('orders.product_count'),
      dataIndex: 'count',
      key: 'count'
    }
  ];

  useEffect(() => {
    const page = searchParams.get('page')
      ? Number(searchParams.get('page'))
      : 1;

    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
    dispatch(
      getOrdersByCustomerId({ id: Number(id), page: page - 1, date: date })
    );
  }, [searchParams, dispatch, setSearchParams, id, date]);

  if (loading) {
    return <Loading />;
  }

  return (
    <Flex gap={24} vertical>
      {orders.results.map(item => (
        <Table
          key={item.id}
          columns={columns}
          dataSource={item.orderProducts}
          rowKey="id"
          title={() => (
            <Typography.Paragraph style={{ marginBottom: 0 }} strong>
              {dayjs(item.created_at).format('DD MMMM, YYYY')}
            </Typography.Paragraph>
          )}
          bordered
          pagination={false}
        />
      ))}
      {orders.count ? (
        <Flex justify="flex-end">
          <Pagination
            pageSize={limit}
            showSizeChanger={false}
            total={orders.count}
            current={Number(searchParams.get('page')) || 1}
            onChange={page => {
              changePage(page);
            }}
          />
        </Flex>
      ) : (
        <Flex
          style={{ width: '100%', height: '500px' }}
          justify="center"
          align="center"
        >
          <Empty />
        </Flex>
      )}
    </Flex>
  );
};

export default OrderByCustomer;
