import { RootState } from 'app/store';

export const selectManagersData = () => (state: RootState) =>
  state.managers.managers;

export const selectManagersLoading = () => (state: RootState) =>
  state.managers.loading;

export const selectManagersLimit = () => (state: RootState) =>
  state.managers.limit;

export const selectManagersLoadingAction = () => (state: RootState) =>
  state.managers.loadingAction;

export const selectCustomerLoadingAction = () => (state: RootState) =>
  state.managers.loadingCustomerAction;

export const selectManagerById = () => (state: RootState) =>
  state.managers.managerById;

export const selectManagerByIdLoading = () => (state: RootState) =>
  state.managers.loadingById;
