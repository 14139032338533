import { Flex, Image, Typography } from 'antd';

import en from '../assets/images/en.png';
import ru from '../assets/images/ru.png';
import hy from '../assets/images/hy.png';

export const LANGUAGES = [
  {
    key: 'en',
    label: (
      <Flex gap={8}>
        <Image src={en} width={30} height={18} preview={false} />
        <Typography.Text>English</Typography.Text>
      </Flex>
    )
  },
  {
    key: 'ru',
    label: (
      <Flex gap={8}>
        <Image src={ru} width={30} height={18} preview={false} />
        <Typography.Text>Русский</Typography.Text>
      </Flex>
    )
  },
  {
    key: 'hy',
    label: (
      <Flex gap={8}>
        <Image src={hy} width={30} height={18} preview={false} />
        <Typography.Text>Հայերեն</Typography.Text>
      </Flex>
    )
  }
];
