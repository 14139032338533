import { RootState } from 'app/store';

export const selectManagerOrdersData = () => (state: RootState) =>
  state.managersOrders.orders;

export const selectManagerOrdersLimit = () => (state: RootState) =>
  state.managersOrders.limit;

export const selectManagerOrdersPage = () => (state: RootState) =>
  state.managersOrders.page;

export const selectManagerOrdersLoading = () => (state: RootState) =>
  state.managersOrders.loading;

export const selectOrderProductsById = () => (state: RootState) =>
  state.managersOrders.orderProducts;

export const selectOrderProductsByIdLoading = () => (state: RootState) =>
  state.managersOrders.loadingOrderProducts;

export const selectManagerInfoLoading = () => (state: RootState) =>
  state.managersOrders.loadingManagerInfo;

export const selectManagerOrderInfo = () => (state: RootState) =>
  state.managersOrders.orderInfo;
