import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout } from 'antd';
import styled from 'styled-components';

export const LayoutStyled = styled(Layout)`
  min-height: 100vh;
`;

export const SiderStyled = styled(Layout.Sider)`
  && {
    height: 100vh;
    position: fixed;
    left: 0;
    top: 64px;
  }
`;

export const SiderTop = styled.div<{ $collapsed: boolean }>`
  display: flex;
  width: 100%;
  justify-content: ${props => (props.$collapsed ? 'center' : 'end')};
  padding: 10px;
  align-items: center;
  height: 64px;
`;

export const MenuFoldIcon = styled(MenuFoldOutlined)`
  color: white;
  font-size: 25px;
`;

export const MenuUnFoldIcon = styled(MenuUnfoldOutlined)`
  color: white;
  font-size: 25px;
`;

export const StyledLayoutContent = styled(Layout)<{ $collapsed: boolean }>`
  max-width: ${props =>
    props.$collapsed ? 'calc(100% - 72px)' : 'calc(100% - 250px)'};
  padding: 24px;
  margin-top: 70px;
  transition: all 0.3s;
`;
