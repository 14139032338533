import { Input, Progress, Table, Typography } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledTable } from './styled';

interface IProps {
  data: {
    customer: string;
    orders: number;
  }[];
  customerCount?: number;
  orderCount?: number;
}

const CustomerTable: FC<IProps> = ({ data, customerCount, orderCount }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const handleSearch = e => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(item =>
    item.customer.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <StyledTable span={6}>
      <Input.Search
        style={{ marginBottom: 8 }}
        value={searchTerm}
        onChange={handleSearch}
        placeholder={t('dashboard.search_customers')}
      />
      <Table
        columns={[
          {
            title: `${t('dashboard.customers')} (${customerCount || 0})`,
            key: 'customer',
            dataIndex: 'customer',
            render(_, record) {
              return (
                <>
                  <Typography.Paragraph style={{ margin: 0 }}>
                    {record.customer}
                  </Typography.Paragraph>
                  <Progress
                    percent={Math.round(
                      (record.orders / (orderCount || 1)) * 100
                    )}
                    size="small"
                    status="normal"
                  />
                </>
              );
            }
          },
          {
            key: 'orders',
            dataIndex: 'orders',
            title: `${t('dashboard.orders')} (${orderCount || 0})`,
            align: 'right'
          }
        ]}
        dataSource={filteredData}
        pagination={false}
        rowKey="customer"
        scroll={{
          y: 504
        }}
      />
    </StyledTable>
  );
};

export default CustomerTable;
