import { TFunction } from 'react-i18next';

export const PATTERNS = {
  price: /^[0-9]*\.?[0-9]*$/
};

export const URL_VALIDATION = new RegExp(
  // eslint-disable-next-line no-useless-escape
  /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi
);

export const validateMessages = (t: TFunction) => ({
  default: t('validation.default'),
  required: t('validation.required'),
  enum: t('validation.enum'),
  whitespace: t('validation.whitespace'),
  date: {
    format: t('validation.date_format'),
    parse: t('validation.date_parse'),
    invalid: t('validation.date_invalid')
  },
  types: {
    string: t('validation.types_string'),
    method: t('validation.types_method'),
    array: t('validation.types_array'),
    object: t('validation.types_object'),
    number: t('validation.types_number'),
    date: t('validation.types_date'),
    boolean: t('validation.types_boolean'),
    integer: t('validation.types_integer'),
    float: t('validation.types_float'),
    regexp: t('validation.types_regexp'),
    email: t('validation.types_email'),
    url: t('validation.types_url'),
    hex: t('validation.types_hex')
  },
  string: {
    len: t('validation.string_len'),
    min: t('validation.string_min'),
    max: t('validation.string_max'),
    range: t('validation.string_range')
  },
  number: {
    len: t('validation.number_len'),
    min: t('validation.number_min'),
    max: t('validation.number_max'),
    range: t('validation.number_range')
  },
  array: {
    len: t('validation.array_len'),
    min: t('validation.array_min'),
    max: t('validation.array_max'),
    range: t('validation.array_range')
  },
  pattern: {
    mismatch: t('validation.pattern_mismatch')
  }
});
