import { FC } from 'react';
import { Button, Flex, Dropdown, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { logOut } from 'app/features/auth/slice';
import {
  selectAuthLoading,
  selectUserIsLoggedIn
} from 'app/features/auth/selectors';
import { DownOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import Logo from 'components/MainLayout/Header/Logo';
import { StyledHeader } from './styled';
import { LANGUAGES } from 'utils/languages';

const Header: FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectAuthLoading());

  const isLoggedIn = useAppSelector(selectUserIsLoggedIn());

  const activeLang = LANGUAGES.find(item => item.key === i18n.language);

  const handleClickLang = (info: { key: string }) => {
    i18n.changeLanguage(info.key);
    localStorage.setItem('locale', info.key);
  };

  const { confirm } = Modal;

  const showConfirm = () => {
    confirm({
      title: t('header.logout_confirm'),
      icon: <ExclamationCircleFilled />,
      onOk() {
        dispatch(logOut());
      }
    });
  };

  return (
    <StyledHeader>
      <Logo />
      <Flex gap={16}>
        <Dropdown
          menu={{ items: LANGUAGES, onClick: handleClickLang }}
          placement="bottom"
          trigger={['hover']}
        >
          <Button>
            <Flex style={{ minWidth: '130px' }} gap={12}>
              {activeLang?.label}
              <DownOutlined style={{ marginTop: '2px' }} />
            </Flex>
          </Button>
        </Dropdown>
        {isLoggedIn && (
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={showConfirm}
          >
            {t('header.logout')}
          </Button>
        )}
      </Flex>
    </StyledHeader>
  );
};

export default Header;
