import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { RootState } from 'app/store';
import { Modal } from 'antd';

import {
  CreateCustomersBody,
  CustomersState,
  UpdateCustomersBody
} from './types';
import { selectCustomersLimit, selectOrderByIdLimit } from './selectors';

const initialState: CustomersState = {
  customers: { results: [], total: 0 },
  customerById: null,
  orderByCustomer: { results: [], count: 0 },
  loading: false,
  loadingById: false,
  loadingAction: false,
  loadingOrderById: false,
  orderLimit: 10,
  limit: 10
};

interface GetAllCustomersParams {
  search?: string | null;
  page?: number | null;
  managerId?: string;
}

interface OrderByCustomerParams {
  id: number;
  page?: number | null;
  date?: string | null;
}
export const getAllCustomers = createAsyncThunk(
  'customers/getAllCustomers',
  async (
    params: GetAllCustomersParams | undefined,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const limit = selectCustomersLimit()(state);
      const res = await client.get('customers', {
        params: {
          page: params?.page,
          limit,
          managerId: params?.managerId,
          name: params?.search
        }
      });

      return res.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getCustomerById = createAsyncThunk(
  'customers/getCustomerById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.get(`/customers/${id}`);

      return dispatch(setCustomerById(response.data.data));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getOrdersByCustomerId = createAsyncThunk(
  'customers/getOrdersByCustomerId',
  async (params: OrderByCustomerParams, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const limit = selectOrderByIdLimit()(state);
      const response = await client.get(`/customers/id-data/${params.id}`, {
        params: {
          page: params.page,
          date: params.date,
          limit
        }
      });

      return response.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createCustomer = createAsyncThunk(
  'customers/createCustomer',
  async (body: CreateCustomersBody, { rejectWithValue }) => {
    try {
      await client.post('customers', body);
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customers/updateCustomers',
  async ({ id, ...data }: UpdateCustomersBody, { rejectWithValue }) => {
    try {
      await client.put(`/customers/${id}`, {
        ...data
      });
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const removeCustomer = createAsyncThunk(
  'customers/removeCustomer',
  async (id: number, { rejectWithValue }) => {
    try {
      await client.delete(`customers/${id}`);
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const uploadCustomersExcel = createAsyncThunk(
  'customer/uploadCustomersExcel',
  async (
    { file, successMessage }: { file: File; successMessage: string },
    { rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await client.post('/customers/customers-upload', formData).then(() => {
        Modal.success({
          content: successMessage
        });
      });
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCustomerById: (state, action) => {
      state.customerById = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllCustomers.pending, state => {
        state.loading = true;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.customers = action.payload;
        state.loading = false;
      })
      .addCase(getAllCustomers.rejected, state => {
        state.loading = false;
      })
      .addCase(getCustomerById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getCustomerById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getCustomerById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(createCustomer.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createCustomer.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createCustomer.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(removeCustomer.pending, state => {
        state.loadingAction = true;
      })
      .addCase(removeCustomer.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(removeCustomer.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateCustomer.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateCustomer.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateCustomer.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(getOrdersByCustomerId.pending, state => {
        state.loadingOrderById = true;
      })
      .addCase(getOrdersByCustomerId.fulfilled, (state, action) => {
        state.orderByCustomer = action.payload;
        state.loadingOrderById = false;
      })
      .addCase(getOrdersByCustomerId.rejected, state => {
        state.loadingOrderById = false;
      });
  }
});

export const { setCustomerById } = customersSlice.actions;
export default customersSlice.reducer;
