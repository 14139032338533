import React, { FC, useEffect } from 'react';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Empty } from 'antd';

import { ChartContainer } from './styled';

interface IProps {
  dataOrders: {
    customer: string;
    orders: number;
  }[];
}

const CustomersOrdersChart: FC<IProps> = ({ dataOrders }) => {
  useEffect(() => {
    if (!dataOrders?.length) {
      return;
    }

    const root = am5.Root.new('customersOrderdiv');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        paddingLeft: 0,
        layout: root.verticalLayout
      })
    );

    const colors =
      chart.get('colors') ||
      am5.ColorSet.new(root, {} as am5.IColorSetSettings);

    const data = dataOrders.map(item => {
      const fillColor = colors.next();

      return {
        ...item,
        columnSettings: { fill: fillColor }
      };
    });

    const xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      minorGridEnabled: true
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'customer',
        renderer: xRenderer
      })
    );

    xRenderer.grid.template.setAll({
      location: 1
    });

    xRenderer.labels.template.setAll({
      paddingTop: 20,
      visible: false // Hides the labels
    });

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        })
      })
    );

    const series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'orders',
        categoryXField: 'customer'
      })
    );

    series.columns.template.setAll({
      tooltipText: '{categoryX}: {valueY}',
      tooltipY: 0,
      strokeOpacity: 0,
      templateField: 'columnSettings'
    });

    series.data.setAll(data);

    series.appear();
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [dataOrders]);

  return dataOrders ? (
    <ChartContainer style={{ height: 550 }}>
      <div id="customersOrderdiv" className="chartdiv" />
      <div className="remove_logo" />
    </ChartContainer>
  ) : (
    <Empty className="chartdiv" />
  );
};

export default CustomersOrdersChart;
