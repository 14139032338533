import { Spin } from 'antd';
import { FC } from 'react';

import { StyledLoading } from './styled';

interface IProps {
  size?: 'small' | 'large';
}

const Loading: FC<IProps> = ({ size = 'large' }) => {
  return (
    <StyledLoading>
      <Spin size={size} />
    </StyledLoading>
  );
};

export default Loading;
