import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { Empty } from 'antd';
import { useEffect, useRef } from 'react';

import { ChartContainer } from './styled';

interface IProps {
  color?: string;
  data: {
    Date: string;
    Count: number;
  }[];
}

const OrderByDate: React.FC<IProps> = ({ data, color }) => {
  const chartRef = useRef<am5xy.XYChart | null>(null);
  useEffect(() => {
    if (!data?.length) {
      return;
    }

    const root = am5.Root.new('OrdersDateChart');

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        pinchZoomX: true,
        paddingLeft: 0
      })
    );

    const cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none'
      })
    );

    cursor.lineY.set('visible', false);

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        maxDeviation: 1,
        marginBottom: 20,
        baseInterval: {
          timeUnit: 'day',
          count: 1
        },
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 80,
          minorGridEnabled: true,
          pan: 'zoom'
        }),
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 1,
        renderer: am5xy.AxisRendererY.new(root, {
          pan: 'zoom'
        })
      })
    );

    const series = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        name: 'Series',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'Count',
        valueXField: 'Date',
        tooltip: am5.Tooltip.new(root, {
          labelText: '{valueY}'
        })
      })
    );

    series.strokes.template.setAll({
      strokeWidth: 3
    });

    series.fills.template.setAll({
      visible: true,
      fillOpacity: 0.2
    });

    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 0,
        sprite: am5.Circle.new(root, {
          radius: 4,
          stroke: root.interfaceColors.get('background'),
          strokeWidth: 2,
          fill: series.get('fill')
        })
      });
    });
    color && series.set('fill', am5.color(color));
    color && series.set('stroke', am5.color(color));
    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal'
      })
    );

    const processedData = data.map(item => {
      return {
        Date: new Date(item.Date).getTime(),
        Count: item.Count
      };
    });

    series.data.setAll(processedData);

    chartRef.current = chart;

    return () => {
      root.dispose();
    };
  }, [color, data]);

  return data ? (
    <ChartContainer style={{ height: 450 }}>
      <div id="OrdersDateChart" className="chartdiv" />
      <div className="remove_logo" />
    </ChartContainer>
  ) : (
    <Empty className="chartdiv" />
  );
};

export default OrderByDate;
