import { DatePicker } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  padding-bottom: 20px;
  width: 100%;
`;

export const SearchContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  max-width: 800px;
  width: 100%;
  .select-wrapper {
    min-width: 241px;
  }
`;
export const DatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  width: 100%;
  gap: 12px;
`;

export const DatePickerStyled = styled(DatePicker)`
  max-width: 288px;
  width: 100%;
`;
