import styled from 'styled-components';

export const ChartContainer = styled.div`
  position: relative;
  height: 90%;
  width: 100%;
  .chartdiv {
    width: 100%;
    height: 100%;
  }
  .remove_logo {
    position: absolute;
    width: 100%;
    height: 19px;
    background: white;
    bottom: 0;
  }
`;
