import { Outlet } from 'react-router-dom';

import { Content, StyledForm } from './styled';
import Header from 'components/MainLayout/Header';

const Auth = () => {
  return (
    <Content>
      <Header />
      <StyledForm>
        <div>
          <Outlet />
        </div>
      </StyledForm>
    </Content>
  );
};

export default Auth;
