import styled from 'styled-components';

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 20px;
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 500px;
  width: 100%;
`;
