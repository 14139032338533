import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { client } from 'api';

import { AuthState, IForgotPassword, ILogin, INewPassword } from './types';

const initialState: AuthState = {
  isLoggedIn: !!localStorage.getItem('token'),
  user: null,
  loading: false
};

export const postLogin = createAsyncThunk(
  'auth/postLogin',
  async (values: ILogin, { rejectWithValue }) => {
    try {
      const res = await client.post('auth/sign-in', values);

      localStorage.setItem('token', res.data.data.token);
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (values: IForgotPassword, { rejectWithValue }) => {
    try {
      await client.post('/auth/forgot', values);
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const setNewPassword = createAsyncThunk(
  'auth/setNewPassword',
  async (values: INewPassword, { rejectWithValue }) => {
    try {
      await client.post(
        '/auth/reset-pass',
        {
          password: values.password
        },
        {
          params: {
            token: values.token
          }
        }
      );
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const logOut = createAsyncThunk('auth/logOut', async () => {
  try {
    localStorage.removeItem('token');
  } catch (error) {
    return showErrorMessage(error as AxiosError<ErrorType>);
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(postLogin.pending, state => {
        state.loading = true;
      })
      .addCase(postLogin.fulfilled, state => {
        state.loading = false;
        state.isLoggedIn = true;
      })
      .addCase(postLogin.rejected, state => {
        state.loading = false;
      })
      .addCase(forgotPassword.pending, state => {
        state.loading = true;
      })
      .addCase(forgotPassword.fulfilled, state => {
        state.loading = false;
      })
      .addCase(forgotPassword.rejected, state => {
        state.loading = false;
      })
      .addCase(setNewPassword.pending, state => {
        state.loading = true;
      })
      .addCase(setNewPassword.fulfilled, state => {
        state.loading = false;
      })
      .addCase(setNewPassword.rejected, state => {
        state.loading = false;
      })
      .addCase(logOut.fulfilled, state => {
        state.isLoggedIn = false;
      });
  }
});

export default authSlice.reducer;
