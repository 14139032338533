import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';
import { client } from 'api';
import { RootState } from 'app/store';
import { Modal } from 'antd';

import {
  AssignProductBody,
  CreateProductBody,
  GetProductByCodeParams,
  RemoveProductBody,
  State,
  UpdateProductBody
} from './types';
import { selectProductsLimit } from './selectors';
import { getLocations } from '../locations/slice';

const initialState: State = {
  products: { results: [], total: 0 },
  productById: null,
  page: 0,
  limit: 10,
  loadingAction: false,
  loading: true,
  loadingById: true,
  productsByCode: []
};

interface GetAllProductsParams {
  locations?: string | null;
  search?: string | null;
  page?: number;
}

export const getAllProducts = createAsyncThunk(
  'product/allProducts',
  async (
    params: GetAllProductsParams | undefined,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const limit = selectProductsLimit()(state);
      const res = await client.get('products', {
        params: {
          page: params?.page,
          limit,
          name: params?.search,
          locations: params?.locations
        }
      });

      return res.data.data;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getProductById = createAsyncThunk(
  'product/productById',
  async (
    { id, row_id }: { id: number; row_id?: number },
    { rejectWithValue }
  ) => {
    try {
      const res = await client.get(`products/${id}`, {
        params: {
          row_id
        }
      });

      return res.data.data;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const getProductByCode = createAsyncThunk(
  'product/productByCode',
  async ({ name, id }: GetProductByCodeParams, { rejectWithValue }) => {
    try {
      const res = await client.get(`products/product-search`, {
        params: {
          name,
          row_id: id
        }
      });

      return res.data.data;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const updateProduct = createAsyncThunk(
  'product/updateProduct',
  async ({ id, ...data }: UpdateProductBody, { rejectWithValue, dispatch }) => {
    try {
      const res = await client.put(`products/${id}`, { ...data });
      await dispatch(getAllProducts());

      return res.data.data;
    } catch (error) {
      return rejectWithValue(showErrorMessage(error as AxiosError<ErrorType>));
    }
  }
);

export const uploadProductExcel = createAsyncThunk(
  'product/uploadProductExcel',
  async (
    { file, successMessage }: { file: File; successMessage: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      await client.post('products/upload-excel', formData).then(() => {
        Modal.success({
          content: successMessage
        });
      });
      await dispatch(getAllProducts());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createProduct = createAsyncThunk(
  'product/createProduct',
  async (body: CreateProductBody, { dispatch, rejectWithValue }) => {
    try {
      await client.post('products', body);
      await dispatch(getAllProducts());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const assignProduct = createAsyncThunk(
  'product/assignProduct',
  async (body: AssignProductBody, { dispatch, rejectWithValue }) => {
    try {
      await client.post('products/save-product', body);
      await dispatch(getLocations());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const decrementProductFromRow = createAsyncThunk(
  'product/assignProduct',
  async (body: RemoveProductBody, { dispatch, rejectWithValue }) => {
    try {
      await client.post('products/remove-product', body);
      await dispatch(getLocations());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const removeProductFromRow = createAsyncThunk(
  'product/assignProduct',
  async (body: RemoveProductBody, { dispatch, rejectWithValue }) => {
    try {
      await client.post('products/remove', body);
      await dispatch(getLocations());
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setProductByCode: (state, action) => {
      state.productsByCode = action.payload;
    },
    setProductById: (state, action) => {
      state.productById = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllProducts.pending, state => {
        state.loading = true;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
      })
      .addCase(getAllProducts.rejected, state => {
        state.loading = false;
      })
      .addCase(getProductById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productById = action.payload;
        state.loadingById = false;
      })
      .addCase(getProductById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(getProductByCode.fulfilled, (state, action) => {
        state.productsByCode = action.payload;
      })
      .addCase(removeProductFromRow.pending, state => {
        state.loadingAction = true;
      })
      .addCase(removeProductFromRow.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(removeProductFromRow.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(createProduct.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createProduct.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createProduct.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateProduct.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateProduct.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateProduct.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setProductByCode, setProductById } = productSlice.actions;

export default productSlice.reducer;
