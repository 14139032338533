import { Navigate, Outlet, useRoutes } from 'react-router';

import Locations from 'pages/Locations';
import Products from 'pages/Products';
import MainLayout from 'components/MainLayout';
import Customers from 'pages/Customers';
import CustomersDetails from 'pages/CustomersDetails';
import Dashboard from 'pages/Dashboard';
import Employees from 'pages/Employees';
import Managers from 'pages/Managers';
import ManagerDetails from 'pages/ManagerDetails';
import WarehouseOrders from 'pages/WarehouseOrders';
import ManagerOrders from 'pages/ManagerOrders';
import ManagerOrderDetails from 'pages/ManagerOrderDetails';

const privateRoutes = [
  {
    path: '/',
    element: <Dashboard />
  },
  {
    path: '/locations',
    element: <Locations />
  },
  {
    path: '/products',
    element: <Products />
  },
  {
    path: '/customers',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Customers />
      },
      {
        path: 'details/:id',
        element: <CustomersDetails />
      }
    ]
  },
  {
    path: '/employees',
    element: <Employees />
  },
  {
    path: '/orders',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Navigate to="warehouse" replace />
      },
      {
        path: 'warehouse',
        element: <WarehouseOrders />
      },
      {
        path: 'managers',
        element: <Outlet />,
        children: [
          {
            path: '',
            element: <ManagerOrders />
          },
          {
            path: 'details/:id',
            element: <ManagerOrderDetails />
          }
        ]
      }
    ]
  },
  {
    path: '/managers',
    element: <Outlet />,
    children: [
      {
        path: '',
        element: <Managers />
      },
      {
        path: 'details/:id',
        element: <ManagerDetails />
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/" replace />
  }
];

const PrivateRoutes = () => {
  const routers = useRoutes(privateRoutes);

  return <MainLayout>{routers}</MainLayout>;
};

export default PrivateRoutes;
