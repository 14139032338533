import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { CreateEmployees, EmployeesState, UpdateEmployees } from './types';
import { selectEmployeesLimit } from './selectors';

const initialState: EmployeesState = {
  employees: { results: [], total: 0 },
  employeesById: null,
  loading: false,
  loadingById: false,
  loadingAction: false,
  limit: 10
};

interface GetAllEmployeesParams {
  search?: string | null;
  page?: number | null;
  limit?: number;
}

export const getAllEmployees = createAsyncThunk(
  'employees/getAllEmployees',
  async (
    params: GetAllEmployeesParams | undefined,
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const limit = selectEmployeesLimit()(state);
      const res = await client.get('workers', {
        params: {
          page: params?.page,
          limit: params?.limit || limit,
          name: params?.search
        }
      });

      return res.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const getEmployeesById = createAsyncThunk(
  'employees/getEmployeesById',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await client.get(`/workers/${id}`);

      return dispatch(setEmployeesById(response.data.data));
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const createEmployees = createAsyncThunk(
  'employees/createEmployees',
  async (body: CreateEmployees, { rejectWithValue }) => {
    try {
      await client.post('workers', body);
    } catch (err) {
      return rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const updateEmployees = createAsyncThunk(
  'employees/updateEmployees',
  async ({ id, ...data }: UpdateEmployees, { rejectWithValue }) => {
    try {
      await client.put(`/workers/${id}`, {
        ...data
      });
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

export const removeEmployees = createAsyncThunk(
  'employees/removeEmployees',
  async (id: number, { rejectWithValue }) => {
    try {
      await client.delete(`workers/${id}`);
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const employeesSlice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setEmployeesById: (state, action) => {
      state.employeesById = action.payload;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getAllEmployees.pending, state => {
        state.loading = true;
      })
      .addCase(getAllEmployees.fulfilled, (state, action) => {
        state.employees = action.payload;
        state.loading = false;
      })
      .addCase(getAllEmployees.rejected, state => {
        state.loading = false;
      })
      .addCase(getEmployeesById.pending, state => {
        state.loadingById = true;
      })
      .addCase(getEmployeesById.fulfilled, state => {
        state.loadingById = false;
      })
      .addCase(getEmployeesById.rejected, state => {
        state.loadingById = false;
      })
      .addCase(createEmployees.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(createEmployees.pending, state => {
        state.loadingAction = true;
      })
      .addCase(createEmployees.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(removeEmployees.pending, state => {
        state.loadingAction = true;
      })
      .addCase(removeEmployees.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(removeEmployees.rejected, state => {
        state.loadingAction = false;
      })
      .addCase(updateEmployees.pending, state => {
        state.loadingAction = true;
      })
      .addCase(updateEmployees.fulfilled, state => {
        state.loadingAction = false;
      })
      .addCase(updateEmployees.rejected, state => {
        state.loadingAction = false;
      });
  }
});

export const { setEmployeesById } = employeesSlice.actions;
export default employeesSlice.reducer;
