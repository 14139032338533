import { Layout } from 'antd';
import styled from 'styled-components';

export const StyledHeader = styled(Layout.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #001529;
  height: 70px;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 20px 20px 0px #b4b5b61a;
  position: fixed;
  z-index: 11;
`;
