import { App, Button, Flex, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  EyeOutlined,
  ExclamationCircleFilled
} from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  selectLocationsData,
  selectLocationsLoading,
  selectLocationsLoadingAction
} from 'app/features/locations/selectors';
import {
  deleteLocations,
  getLocationById,
  getLocations,
  setSelectedLocationId
} from 'app/features/locations/slice';
import { ILocations } from 'app/features/locations/types';
import { useNavigate } from 'react-router';
import { selectLocationModalType } from 'app/features/locationModal/selectors';
import { setLocationModalType } from 'app/features/locationModal/slice';

import LocationsHeader from './components/LocationsHeader';
import LocationModal from './components/LocationModal';
import SearchProductModal from './components/SearchProductModal';
import ProductModal from './components/ProductModal';
import UpdateModal from './components/UpdateModal';

const Locations = () => {
  const { modal } = App.useApp();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector(selectLocationsLoading());
  const loadingAction = useAppSelector(selectLocationsLoadingAction());
  const locationsData = useAppSelector(selectLocationsData());
  const [locationModal, setLocationModal] = useState(false);
  const [location, setLocation] = useState<ILocations>();
  const [parentId, setParentId] = useState<null | number>(null);
  const modalType = useAppSelector(selectLocationModalType);

  const addLevels = (nodes, level = 0) => {
    return nodes?.map(node => {
      const { children, ...rest } = node;

      return {
        ...rest,
        level,
        ...(children &&
          children?.length && { children: addLevels(children, level + 1) })
      };
    });
  };

  const locationsWithLevels = addLevels(locationsData);

  const showDeleteConfirm = (row: ILocations) => {
    modal.confirm({
      title: t('common.warning'),
      content: t('locations.delete_row_warning'),
      icon: <ExclamationCircleFilled />,
      okText: t('common.yes'),
      cancelText: t('common.no'),
      okType: 'danger',
      onOk() {
        removeLocation(row);
      },
      okButtonProps: {
        loading: loadingAction
      }
    });
  };

  const columns: ColumnsType<ILocations> = [
    {
      title: t('common.title'),
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '',
      dataIndex: 'view_products',
      key: 'view_products',
      width: 200,
      render: (_, row) => {
        return (
          row.is_assigned && (
            <Button
              onClick={() => navigate(`/products?filter=${row.id}`)}
              size="large"
            >
              {t('locations.view_products')}
              <EyeOutlined />
            </Button>
          )
        );
      }
    },
    {
      title: '',
      dataIndex: 'assign_product',
      key: 'assign_product',
      width: 200,
      render: (_, row) => {
        return (
          !row.children && (
            <Button
              onClick={() => {
                setLocation(row);
                dispatch(setLocationModalType('search-product'));
                dispatch(setSelectedLocationId(row.id));
              }}
              size="large"
            >
              {t('locations.assign_product')}
            </Button>
          )
        );
      }
    },
    {
      title: t('locations.action'),
      dataIndex: 'action',
      width: 100,
      key: 'Action',
      align: 'center',
      render: (_, row) => {
        return (
          <Flex justify="flex-end" gap={16}>
            {!row.children && (
              <Button
                danger
                onClick={() => showDeleteConfirm(row)}
                size="large"
                icon={<DeleteOutlined style={{ fontSize: 18 }} />}
              />
            )}
            {row.level !== 2 && (
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  setParentId(row.id);
                  setLocationModal(true);
                }}
                icon={<PlusOutlined style={{ fontSize: 18 }} />}
              />
            )}
            <Button
              size="large"
              onClick={async () => {
                await dispatch(getLocationById(row.id));
                setLocationModal(true);
              }}
              icon={<EditOutlined style={{ fontSize: 18 }} />}
            />
          </Flex>
        );
      }
    }
  ];

  const removeLocation = (row: ILocations) => {
    return dispatch(deleteLocations(row.id));
  };

  const closeLocationModal = () => {
    setParentId(null);
    setLocationModal(false);
  };

  useEffect(() => {
    dispatch(getLocations());
  }, [dispatch]);

  const renderModal = () => {
    switch (modalType) {
      case 'search-product':
        return <SearchProductModal location={location} />;

      case 'product':
        return <ProductModal location={location} />;

      case 'update':
        return <UpdateModal location={location} />;

      case 'assign':
        return <UpdateModal location={location} />;
    }
  };

  return (
    <>
      <LocationsHeader
        isOpenModal={() => setLocationModal(true)}
        title={t('common.locations')}
      />
      <Table
        loading={loading}
        columns={columns}
        dataSource={locationsWithLevels}
        rowKey="id"
        pagination={false}
      />
      <LocationModal
        isOpen={locationModal}
        parentId={parentId}
        onClose={closeLocationModal}
      />
      {renderModal()}
    </>
  );
};

export default Locations;
