import styled from 'styled-components';

export const StyledOrderItem = styled.div`
  .ant-descriptions-header {
    margin-bottom: 10px;
  }

  &&&& {
    .ant-collapse-content-box {
      padding: 0;
    }
  }
`;
