import { RootState } from 'app/store';

export const selectLocationsData = () => (state: RootState) =>
  state.locations.locations;

export const selectLocationById = () => (state: RootState) =>
  state.locations.locationById;

export const selectLocationByIdLoading = () => (state: RootState) =>
  state.locations.loadingById;

export const selectLocationsLoading = () => (state: RootState) =>
  state.locations.loading;

export const selectLocationsLoadingAction = () => (state: RootState) =>
  state.locations.loadingAction;

export const selectSelectedLocationId = (state: RootState) =>
  state.locations.selectedLocationId;
