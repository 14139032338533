import { ThemeConfig } from 'antd/es/config-provider/context';

export const customTheme: ThemeConfig = {
  token: {
    colorWarning: '#52c41a',
    // colorPrimary: '#722ed1',
    fontFamily: 'Inter'
  },
  components: {
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0
    }
  }
};
