import {
  Collapse,
  Descriptions,
  DescriptionsProps,
  Divider,
  Table
} from 'antd';
import { IOrderProducts, IOrders } from 'app/features/warehouseOrders/types';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'app/hooks';
import {
  selectOrderDataById,
  selectOrderLoadingById
} from 'app/features/warehouseOrders/selectors';
import { ColumnsType } from 'antd/lib/table';

import { StyledOrderItem } from './styled';

interface IProps {
  order: IOrders;
  activeOrder: boolean;
  onClick: (id: number) => void;
}

const OrdersItem: FC<IProps> = ({ order, onClick, activeOrder }) => {
  const { t } = useTranslation();
  const orderProducts = useAppSelector(selectOrderDataById());
  const loadingById = useAppSelector(selectOrderLoadingById());
  const descriptionItems: DescriptionsProps['items'] = [
    {
      key: 'name',
      label: t('orders.customer_name'),
      children: order.customer.name
    },
    {
      key: 'code',
      label: t('orders.customer_code'),
      children: order.customer.code
    },
    {
      key: 'email',
      label: t('orders.customer_email'),
      children: order.customer.customers_info.email
    },
    {
      key: 'phone',
      label: t('orders.customer_phone'),
      children: order.customer.customers_info.phone
    },
    {
      key: 'worker',
      label: t('orders.worker'),
      children: `${order.worker?.name || ''} ${order.worker?.surname || ''}`
    }
  ];

  const columnsProduct: ColumnsType<IOrderProducts> = [
    {
      title: t('product.product_name'),
      dataIndex: ['products', 'name'],
      key: 'product_name'
    },
    {
      title: t('orders.product_code'),
      dataIndex: ['products', 'code'],
      key: 'product_code'
    },
    {
      title: t('orders.product_barcode'),
      dataIndex: ['products', 'barcode'],
      key: 'product_barcode'
    },
    {
      title: t('orders.location'),
      dataIndex: 'row_name',
      key: 'row_name'
    },
    {
      title: t('orders.product_count'),
      dataIndex: 'count',
      key: 'count'
    }
  ];

  return (
    <StyledOrderItem>
      <Descriptions
        title={
          <Divider style={{ marginBottom: 0 }} orientation="left">
            {dayjs(order.created_at).format('DD MMMM, YYYY')}
          </Divider>
        }
        layout="vertical"
        bordered
        column={5}
        items={descriptionItems}
      />
      <Collapse
        onChange={() => onClick(order.id)}
        activeKey={activeOrder ? '1' : undefined}
        style={{ marginTop: '4px', marginBottom: '24px' }}
        items={[
          {
            key: '1',
            label: t('orders.order_products'),
            children: activeOrder ? (
              <Table
                style={{ padding: 0 }}
                loading={loadingById}
                columns={columnsProduct}
                dataSource={orderProducts?.orderProducts || []}
                pagination={false}
              />
            ) : null
          }
        ]}
      />
    </StyledOrderItem>
  );
};

export default OrdersItem;
