import {
  App,
  Button,
  Card,
  Col,
  Flex,
  Form,
  Image,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
  Tag,
  Typography,
  Upload
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { selectManagerById } from 'app/features/managers/selectors';
import dayjs from 'dayjs';
import { getManagerById, updateManager } from 'app/features/managers/slice';
import { UpdateManager } from 'app/features/managers/types';
import { useParams } from 'react-router';
import { BASE_URL } from 'api';

import defaultImg from '../../../../assets/images/default_image.png';
import {
  StyledAction,
  StyledAvatar,
  StyledDivider,
  StyledDividerText
} from './styled';
import { useUpload } from 'hooks/useUpload';

const { Text, Paragraph } = Typography;
const { Dragger } = Upload;

const ManagerInfo: FC = () => {
  const [availability, setAvailability] = useState(true);
  const [changeInfo, setChangeInfo] = useState(false);
  const { modal } = App.useApp();
  const { onUploadFile } = useUpload();

  const { id } = useParams();
  // form
  const [form] = Form.useForm();

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const managerById = useAppSelector(selectManagerById());

  const onChangeAvailability = (e: RadioChangeEvent) => {
    setAvailability(e.target.value);
  };

  const onSave = async (values: Omit<UpdateManager, 'id'>) => {
    await form.validateFields();

    const newFile = values.file_id?.file?.response.data.data.id;

    if (managerById?.id) {
      return dispatch(
        updateManager({
          id: managerById.id,
          ...values,
          file_id: newFile || managerById.avatar.id,
          active: availability
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          modal.success({ title: t('managers.manager_was_update') });
          dispatch(getManagerById(Number(id)));
          setChangeInfo(false);
        }
      });
    }
  };

  useEffect(() => {
    if (managerById?.id) {
      setAvailability(managerById.active);
      form.setFieldsValue({
        first_name: managerById.first_name,
        last_name: managerById.last_name,
        email: managerById.email,
        phone: managerById.phone
      });
    }
  }, [managerById, form, changeInfo]);

  const removeAvatar = () => {
    if (managerById?.id) {
      dispatch(
        updateManager({
          id: managerById.id,
          first_name: managerById.first_name,
          last_name: managerById.last_name,
          email: managerById.email,
          phone: managerById.phone,
          active: managerById.active
        })
      ).then(res => {
        if (res.meta.requestStatus === 'fulfilled') {
          dispatch(getManagerById(Number(id)));
          setChangeInfo(false);
        }
      });
    }
  };

  return (
    <Card>
      <StyledDivider orientation="left">
        <Flex justify="space-between" align="center">
          <StyledDividerText strong>
            {t('customers.generalInfo')}
          </StyledDividerText>
          <StyledAction>
            {changeInfo ? (
              <Flex gap={16}>
                <Button onClick={() => setChangeInfo(false)}>
                  {t('common.cancel')}
                </Button>
                <Button type="primary" onClick={form.submit}>
                  {t('common.save')}
                </Button>
              </Flex>
            ) : (
              <Button type="primary" onClick={() => setChangeInfo(true)}>
                {t('managers.update_information')}
              </Button>
            )}
          </StyledAction>
        </Flex>
      </StyledDivider>
      <Form onFinish={onSave} form={form} layout="vertical" size="large">
        <Row gutter={24}>
          <Col span={24}>
            <Row gutter={24}>
              <Col span={12}>
                <Flex
                  justify="center"
                  align="center"
                  vertical
                  style={{ marginBottom: changeInfo ? 0 : 24 }}
                >
                  <Image
                    width={160}
                    height={160}
                    preview={false}
                    style={{ borderRadius: 12, objectFit: 'cover' }}
                    src={
                      managerById?.avatar?.name
                        ? `${BASE_URL}/uploads/${managerById?.avatar?.name}`
                        : defaultImg
                    }
                  />
                  {changeInfo && (
                    <StyledAvatar>
                      <Form.Item name="file_id" style={{ marginBottom: 0 }}>
                        <Dragger
                          accept="image/*"
                          maxCount={1}
                          customRequest={onUploadFile}
                          listType="picture"
                          style={{
                            background: 'white',
                            border: 'none',
                            height: 16
                          }}
                        >
                          <div className="action_image">
                            <Button style={{ padding: 0 }} type="link">
                              {t('managers.change_avatar')}
                            </Button>
                            <Button
                              onClick={e => {
                                e.stopPropagation();
                                removeAvatar();
                              }}
                              style={{ padding: 0 }}
                              type="link"
                              danger
                            >
                              {t('managers.remove_avatar')}
                            </Button>
                          </div>
                        </Dragger>
                      </Form.Item>
                    </StyledAvatar>
                  )}
                </Flex>
              </Col>
              <Col span={6}>
                <Flex
                  style={{ height: '100%' }}
                  vertical
                  gap={12}
                  align="center"
                  justify="center"
                >
                  <Text>{t('managers.availability')}</Text>
                  {changeInfo ? (
                    <Radio.Group
                      onChange={onChangeAvailability}
                      value={availability}
                    >
                      <Radio value={true}>{t('managers.active')}</Radio>
                      <Radio value={false}>{t('managers.inactive')}</Radio>
                    </Radio.Group>
                  ) : (
                    <span>
                      {managerById?.active ? (
                        <Tag color="green">{t('managers.active')}</Tag>
                      ) : (
                        <Tag color="red">{t('managers.inactive')}</Tag>
                      )}
                    </span>
                  )}
                </Flex>
              </Col>
              <Col span={6}>
                <Flex
                  vertical
                  gap={12}
                  align="center"
                  justify="center"
                  style={{ height: '100%' }}
                >
                  <Text>{t('managers.created_at')}</Text>
                  <Paragraph strong>
                    {dayjs(managerById?.created_at).format('DD-MM-YYYY')}
                  </Paragraph>
                </Flex>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Form.Item
              name="first_name"
              label={t('managers.first_name')}
              rules={[{ required: true }]}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.first_name_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              rules={[{ required: true }]}
              name="last_name"
              label={t('managers.last_name')}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.last_name_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              label={t('managers.email')}
              rules={[{ type: 'email' }]}
              messageVariables={{
                label: t('validation.input_is_not_valid_email')
              }}
            >
              <Input
                disabled={!changeInfo}
                autoComplete="new-email"
                placeholder={t('managers.email_placeholder')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              label={t('managers.phone')}
              rules={[
                { required: true },
                {
                  // eslint-disable-next-line no-useless-escape
                  pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
                  message: t('validation.enter_phone_number')
                }
              ]}
            >
              <Input
                disabled={!changeInfo}
                placeholder={t('managers.phone_placeholder')}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ManagerInfo;
