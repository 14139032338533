import { DatePicker, Divider, Flex, List, Tag, Typography } from 'antd';
import {
  selectCustomerById,
  selectCustomerByIdLoading
} from 'app/features/customers/selectors';
import { getCustomerById, setCustomerById } from 'app/features/customers/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useSearchParams } from 'react-router-dom';

import OrderByCustomer from './components/OrderByCustomer';
import Loading from 'components/Loading';

const { Title, Text } = Typography;

type IListData = {
  title: string;
  description: ReactNode;
};

const CustomersDetails: FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customerById = useAppSelector(selectCustomerById());
  const loadingById = useAppSelector(selectCustomerByIdLoading());
  const [date, setDate] = useState<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const onChangeDate = (date: string) => {
    searchParams.set('page', '1');
    setSearchParams(searchParams);
    setDate(date ? dayjs(date).format('YYYY-MM-DD') : null);
  };

  useEffect(() => {
    if (id) {
      dispatch(getCustomerById(Number(id)));
    }

    return () => {
      dispatch(setCustomerById(null));
    };
  }, [dispatch, id]);

  if (loadingById) {
    return <Loading />;
  }

  const listData: IListData[] = [
    { title: t('customers.name'), description: customerById?.name || '_____' },
    { title: t('customers.code'), description: customerById?.code || '_____' },
    {
      title: t('customers.email'),
      description: customerById?.customers_info.email || '_____'
    },
    {
      title: t('customers.phone'),
      description: customerById?.customers_info.phone || '_____'
    },
    {
      title: t('customers.address'),
      description: customerById?.customers_info.address || '_____'
    },
    {
      title: t('customers.managers'),
      description:
        customerById?.managers.map((item, index) => (
          <a href={`/managers/details/${item.id}`} key={index}>
            <Tag color="blue" bordered={false}>
              {item.first_name} {item.last_name}
            </Tag>
          </a>
        )) || '_____'
    }
  ];

  return (
    <Flex vertical style={{ width: '100%' }} gap={24}>
      <Flex gap={24}>
        <ArrowLeftOutlined
          onClick={() => navigate('/customers')}
          style={{ fontSize: 24, color: 'black', cursor: 'pointer' }}
        />
        <Title level={2}>{customerById?.name}</Title>
      </Flex>
      <Flex vertical gap={16}>
        <Divider orientation="left">{t('customers.generalInfo')}</Divider>
        <List
          size="small"
          style={{ backgroundColor: 'white' }}
          bordered
          dataSource={listData}
          renderItem={item => (
            <List.Item key={item.title}>
              <List.Item.Meta
                title={<Text>{item.title}</Text>}
                description={item.description}
              />
            </List.Item>
          )}
        />
        <Flex vertical gap={16}>
          <Divider orientation="left">{t('customers.orders')}</Divider>
          <Flex justify="flex-end">
            <DatePicker
              onChange={onChangeDate}
              defaultValue={date}
              style={{ width: '300px' }}
            />
          </Flex>
          <OrderByCustomer date={date} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default CustomersDetails;
