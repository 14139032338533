import { RootState } from 'app/store';

export const selectEmployeesData = () => (state: RootState) =>
  state.employees.employees;

export const selectEmployeesLoading = () => (state: RootState) =>
  state.employees.loading;

export const selectEmployeesLimit = () => (state: RootState) =>
  state.employees.limit;

export const selectEmployeesLoadingAction = () => (state: RootState) =>
  state.employees.loadingAction;

export const selectEmployeesById = () => (state: RootState) =>
  state.employees.employeesById;

export const selectEmployeesByIdLoading = () => (state: RootState) =>
  state.employees.loadingById;
