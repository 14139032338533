import { Divider, Flex, Table, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BASE_URL } from 'api';
import { IManagersOrderProducts } from 'app/features/managersOrders/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  selectOrderProductsByIdLoading,
  selectOrderProductsById,
  selectManagerOrdersLimit
} from 'app/features/managersOrders/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getOrderProductsById } from 'app/features/managersOrders/slice';
import { useParams } from 'react-router';

import defaultImg from '../../../../assets/images/default_image.png';

const OrderProductList = () => {
  const { t } = useTranslation();
  const loadingProducts = useAppSelector(selectOrderProductsByIdLoading());
  const orderProducts = useAppSelector(selectOrderProductsById());
  const limit = useAppSelector(selectManagerOrdersLimit());
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const columns: ColumnsType<IManagersOrderProducts> = [
    {
      title: t('product.image'),
      dataIndex: 'file',
      key: 'file',
      width: 80,
      render: file => (
        <Image
          width={60}
          height={60}
          preview={false}
          style={{ borderRadius: 12, objectFit: 'cover' }}
          src={file ? `${BASE_URL}/uploads/${file}` : defaultImg}
        />
      )
    },
    {
      title: t('product.name'),
      dataIndex: 'productName',
      key: 'productName'
    },
    {
      title: t('product.code'),
      dataIndex: 'productCode',
      key: 'productCode'
    },
    {
      title: t('product.barcode'),
      dataIndex: 'productBarcode',
      key: 'productBarcode'
    },
    {
      title: t('product.price'),
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: t('product.count'),
      dataIndex: 'count',
      key: 'count'
    }
  ];

  useEffect(() => {
    if (id) {
      dispatch(getOrderProductsById({ id: Number(id), page }));
    }
  }, [dispatch, id, page]);

  return (
    <Flex style={{ marginTop: 44 }} vertical gap={16}>
      <Divider orientation="left">{t('orders.products_list')}</Divider>
      <Table
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
          total: orderProducts?.total,
          pageSize: limit
        }}
        loading={loadingProducts}
        columns={columns}
        dataSource={orderProducts?.results}
        rowKey="id"
      />
    </Flex>
  );
};

export default OrderProductList;
