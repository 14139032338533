import { DownOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const DetailsIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const DropdownIcon = styled(DownOutlined)`
  color: #1677ff;
  cursor: pointer;
`;
