export const ORDERS_PAYMENT_VALUES = {
  CASH: 1,
  TRANSFER: 2,
  DEBT: 3
};

export const ORDERS_PAYMENT_LABELS = {
  1: 'cash',
  2: 'transfer',
  3: 'debt'
};

export const ORDERS_PAYMENT_TYPES = [
  {
    value: ORDERS_PAYMENT_VALUES.CASH,
    label: ORDERS_PAYMENT_LABELS[1],
    color: '#28a745'
  },
  {
    value: ORDERS_PAYMENT_VALUES.TRANSFER,
    label: ORDERS_PAYMENT_LABELS[2],
    color: '#007bff'
  },
  {
    value: ORDERS_PAYMENT_VALUES.DEBT,
    label: ORDERS_PAYMENT_LABELS[3],
    color: '#dc3545'
  }
];
export const ORDERS_STATUS_VALUES = {
  PENDING: 1,
  IN_PROGRESS: 2,
  COMPLETED: 3,
  REJECTED: 4
};

export const ORDERS_STATUS_LABELS = {
  1: 'pending',
  2: 'in_progress',
  3: 'completed',
  4: 'rejected'
};

export const ORDERS_STATUS_TYPES = [
  {
    value: ORDERS_STATUS_VALUES.PENDING,
    label: ORDERS_STATUS_LABELS[1],
    color: '#FFA500'
  },
  {
    value: ORDERS_STATUS_VALUES.IN_PROGRESS,
    label: ORDERS_STATUS_LABELS[2],
    color: '#20C997'
  },
  {
    value: ORDERS_STATUS_VALUES.COMPLETED,
    label: ORDERS_STATUS_LABELS[3],
    color: '#28A745'
  },
  {
    value: ORDERS_STATUS_VALUES.REJECTED,
    label: ORDERS_STATUS_LABELS[4],
    color: '#DC3545'
  }
];
