import { Button } from 'antd';
import styled from 'styled-components';

export const StyledButton = styled(Button)`
  display: inline;
  padding: 0;
  margin-left: 4px;
  font-size: 16px;
  font-weight: 500;
`;
