import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { State } from './types';

const initialState: State = {
  modalType: null
};

const locationModalSlice = createSlice({
  name: 'locationModal',
  initialState,
  reducers: {
    setLocationModalType: (
      state,
      action: PayloadAction<State['modalType']>
    ) => {
      state.modalType = action.payload;
    }
  }
});

export const { setLocationModalType } = locationModalSlice.actions;
export default locationModalSlice.reducer;
