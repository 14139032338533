import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { client } from 'api';
import { AxiosError } from 'axios';
import { ErrorType, showErrorMessage } from 'helpers/errors';

import { AnalyticsState } from './types';

const initialState: AnalyticsState = {
  analytics: null,
  loading: false
};

export const getAllAnalytics = createAsyncThunk(
  'analytics/getAllAnalytics',
  async (_, { rejectWithValue }) => {
    try {
      const res = await client.get('orders/charts-data');

      return res.data.data;
    } catch (err) {
      rejectWithValue(showErrorMessage(err as AxiosError<ErrorType>));
    }
  }
);

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getAllAnalytics.pending, state => {
        state.loading = true;
      })
      .addCase(getAllAnalytics.fulfilled, (state, action) => {
        state.analytics = action.payload;
        state.loading = false;
      })
      .addCase(getAllAnalytics.rejected, state => {
        state.loading = false;
      });
  }
});

export default analyticsSlice.reducer;
