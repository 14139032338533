import { Divider, Typography } from 'antd';
import styled from 'styled-components';

export const StyledDivider = styled(Divider)`
  position: relative;
  padding-bottom: 40px;
`;

export const StyledDividerText = styled(Typography.Text)`
  position: absolute;
  left: 20px;
  font-size: 20px;
  background-color: white;
  padding-inline: 16px;
  z-index: 1;
`;

export const StyledAction = styled(Typography.Text)`
  position: absolute;
  right: 20px;
  z-index: 1;
  background-color: white;
  padding-inline: 16px;
`;

export const StyledAvatar = styled.div`
  .ant-upload .ant-upload-btn {
    padding: 0;
  }
  .action_image {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }
`;
