import { Flex, Table, Image } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
  selectAllProducts,
  selectAllProductsLoading,
  selectProductsLimit
} from 'app/features/products/selectors';
import { getAllProducts, setProductById } from 'app/features/products/slice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { LocationsType } from 'app/features/products/types';
import { useSearchParams } from 'react-router-dom';
import { BASE_URL } from 'api';

import defaultImg from '../../assets/images/default_image.png';
import ProductModal from './components/ProductModal';
import ProductHeader from './components/ProductHeader';
import CopyableText from './components/CopyableText';

type columnsType = {
  id: number;
  code: string;
  barcode: string;
  total_amount: string;
  files: { name: string; id: string }[];
  locations: LocationsType[];
};

const Products = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const products = useAppSelector(selectAllProducts());
  const limit = useAppSelector(selectProductsLimit());
  const loading = useAppSelector(selectAllProductsLoading());
  const [openModal, setOpenModal] = useState(false);
  const [productId, setProductId] = useState(0);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(0);

  const onCloseModal = () => {
    setProductId(0);
    setOpenModal(false);
    dispatch(setProductById(null));
  };

  const filterValue = searchParams.get('filter');

  const onChangePage = (value: number) => {
    setPage(value);
  };

  useEffect(() => {
    const searchValue = new URLSearchParams(window.location.search).get(
      'search'
    );

    dispatch(
      getAllProducts({
        search: searchValue,
        page: page,
        locations: filterValue
      })
    );
  }, [page, dispatch, filterValue]);
  const columns: ColumnsType<columnsType> = [
    {
      title: t('product.image'),
      dataIndex: 'files',
      key: 'files',
      width: 80,
      render: file => (
        <Image
          width={60}
          height={60}
          preview={false}
          style={{ borderRadius: 12, objectFit: 'cover' }}
          src={
            file[0]?.name ? `${BASE_URL}/uploads/${file[0]?.name}` : defaultImg
          }
        />
      )
    },
    {
      title: t('product.name'),
      dataIndex: 'name',
      key: 'name',
      render: name => <CopyableText text={name} />
    },
    {
      title: t('product.code'),
      dataIndex: 'code',
      key: 'code',
      render: code => <CopyableText text={code} />
    },
    {
      title: t('product.barcode'),
      dataIndex: 'barcode',
      key: 'barcode',
      render: barcode => <CopyableText text={barcode} />
    },
    {
      title: t('product.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: item => dayjs(item).format('DD-MM-YYYY')
    },
    {
      title: t('product.total'),
      dataIndex: 'total_amount',
      key: 'total_amount'
    },
    {
      title: t('product.price'),
      dataIndex: 'price',
      key: 'price'
    },
    {
      title: (
        <Flex gap={12}>
          <CheckOutlined style={{ color: '#389e0d', fontSize: '18px' }} />
          {t('product.assigned')}
        </Flex>
      ),
      dataIndex: 'count',
      key: 'count'
    },
    {
      title: (
        <Flex gap={12}>
          <CloseOutlined style={{ color: '#d4380d', fontSize: '18px' }} />
          {t('product.unassigned')}
        </Flex>
      ),
      dataIndex: 'unassigned',
      key: 'unassigned'
    },
    {
      title: '',
      dataIndex: 'id',
      width: 40,
      key: 'id',
      align: 'center',
      render: (id: number) => {
        return (
          <Flex justify="space-between">
            <EditOutlined
              onClick={() => {
                setOpenModal(true);
                setProductId(id);
              }}
              style={{ fontSize: 20 }}
            />
          </Flex>
        );
      }
    }
  ];

  return (
    <>
      <ProductHeader onChangePage={onChangePage} title={t('common.products')} />
      <Table
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            setPage(page - 1);
          },
          total: products?.total,
          pageSize: limit
        }}
        loading={loading}
        columns={columns}
        dataSource={products?.results}
        rowKey="id"
      />
      <ProductModal id={productId} onClose={onCloseModal} isOpen={openModal} />
    </>
  );
};

export default Products;
