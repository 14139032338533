import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Flex, Table, Tag } from 'antd';
import {
  getAllManagersOrders,
  setPage
} from 'app/features/managersOrders/slice';
import {
  selectManagerOrdersPage,
  selectManagerOrdersLoading,
  selectManagerOrdersData,
  selectManagerOrdersLimit
} from 'app/features/managersOrders/selectors';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/lib/table';
import { IManagersOrders } from 'app/features/managersOrders/types';
import dayjs from 'dayjs';
import { EyeOutlined } from '@ant-design/icons';
import { ORDERS_PAYMENT_TYPES, ORDERS_STATUS_TYPES } from 'constant/order';

import { _debounce } from 'utils/helpers';
import ManagerOrdersHeader from './components/ManagerOrdersHeader';
import { DetailsIcon } from './styled';

const debounce = _debounce();

const ManagerOrders = () => {
  const [searchParams] = useSearchParams();
  const page = useAppSelector(selectManagerOrdersPage());
  const managersOrders = useAppSelector(selectManagerOrdersData());
  const limit = useAppSelector(selectManagerOrdersLimit());
  const loading = useAppSelector(selectManagerOrdersLoading());
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const columns: ColumnsType<IManagersOrders> = [
    {
      title: t('orders.customer_name'),
      dataIndex: 'customer_name',
      key: 'customer_name'
    },
    {
      title: t('orders.manager_name'),
      dataIndex: 'manager_name',
      key: 'manager_name'
    },
    {
      title: t('orders.created_at'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: item => dayjs(item).format('DD-MM-YYYY')
    },
    {
      title: t('orders.total_price'),
      dataIndex: 'total_price',
      key: 'total_price'
    },
    {
      title: t('orders.payment'),
      dataIndex: 'payment',
      key: 'payment',
      render: item => {
        const payment = ORDERS_PAYMENT_TYPES.find(
          e => String(e.value) === item
        );

        return (
          <Tag color={payment?.color}>{t(`orders.${payment?.label}`)}</Tag>
        );
      }
    },
    {
      title: t('orders.status'),
      dataIndex: 'status',
      key: 'status',
      render: item => {
        const status = ORDERS_STATUS_TYPES.find(e => String(e.value) === item);

        return <Tag color={status?.color}>{t(`orders.${status?.label}`)}</Tag>;
      }
    },
    {
      title: '',
      dataIndex: 'details',
      key: 'details',
      width: 73,
      render: (_, row) => {
        return (
          <DetailsIcon
            onClick={() => navigate(`/orders/managers/details/${row.id}`)}
          >
            <EyeOutlined style={{ fontSize: 20 }} />
          </DetailsIcon>
        );
      }
    }
  ];

  useEffect(() => {
    const searchValue = searchParams.get('search');
    const status = searchParams.get('status');
    const payment = searchParams.get('payment');
    const manager = searchParams.get('manager');
    const date = searchParams.get('date');

    debounce(() => {
      dispatch(
        getAllManagersOrders({
          name: searchValue,
          status: status,
          payment: payment,
          manager: manager,
          date: date
        })
      );
    });
  }, [dispatch, searchParams, page]);

  return (
    <Flex vertical style={{ height: '100%' }}>
      <ManagerOrdersHeader />
      <Table
        loading={loading}
        columns={columns}
        dataSource={managersOrders?.results || []}
        rowKey="id"
        bordered
        pagination={{
          showSizeChanger: false,
          current: page + 1,
          onChange: page => {
            dispatch(setPage(page - 1));
          },
          total: managersOrders?.total || 0,
          pageSize: limit
        }}
      />
    </Flex>
  );
};

export default ManagerOrders;
