import { ArrowLeftOutlined } from '@ant-design/icons';
import { Flex, Form, Input, Typography } from 'antd';
import { selectAuthLoading } from 'app/features/auth/selectors';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { setNewPassword } from 'app/features/auth/slice';

import MessageChangePassword from '../MessageChangePassword';
import { AuthButton } from '../styled';

const { Title } = Typography;

type NewPasswordInput = {
  password: string;
  confirm_password: string;
};

const NewPassword = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const isLoading = useAppSelector(selectAuthLoading());
  const [successMessage, setSuccessMessage] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const token = searchParams.get('token');

        if (!token) {
          throw new Error();
        }
      } catch (_) {
        navigate('/login');
      }
    })();
  }, [navigate, searchParams]);

  const onFinish = async (values: NewPasswordInput) => {
    const res = await dispatch(
      setNewPassword({
        password: values.password,
        token: searchParams.get('token') as string
      })
    );

    if (res.meta.requestStatus === 'fulfilled') {
      setSuccessMessage(true);
    }
  };

  return (
    <>
      {successMessage ? (
        <MessageChangePassword />
      ) : (
        <>
          <Title level={2} style={{ marginBottom: 24 }}>
            {t('auth.reset_password')}
          </Title>
          <Form form={form} layout="vertical" size="large" onFinish={onFinish}>
            <Form.Item
              name="password"
              messageVariables={{
                label: t('auth.new_password_placeholder')
              }}
              rules={[{ required: true }]}
            >
              <Input.Password
                placeholder={t('auth.new_password_placeholder')}
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              messageVariables={{
                label: t('auth.confirm_password_placeholder')
              }}
              rules={[
                { required: true, message: t('auth.passwords_not_match') },
                {
                  message: t('auth.passwords_not_match'),
                  validator: (_, value) => {
                    if (value === form.getFieldValue('password')) {
                      return Promise.resolve();
                    }

                    return Promise.reject();
                  }
                }
              ]}
            >
              <Input.Password
                placeholder={t('auth.confirm_password_placeholder')}
              />
            </Form.Item>
            <Form.Item>
              <AuthButton
                style={{ marginTop: '16px' }}
                type="primary"
                htmlType="submit"
                shape="round"
                loading={isLoading}
              >
                {t('auth.reset_password')}
              </AuthButton>
            </Form.Item>
            <Flex>
              <Link to="/login" style={{ color: '#2D2C4C' }}>
                <ArrowLeftOutlined style={{ marginRight: 12 }} />
                {t('auth.back_login')}
              </Link>
            </Flex>
          </Form>
        </>
      )}
    </>
  );
};

export default NewPassword;
